import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from '../context/DataContext';

//mui elemek
import { Box, Typography, Avatar, Menu, MenuItem, ListItemIcon } from '@mui/material';

//iconok és képek
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';

const PageHeaderUserDetailsBody = () => {
  const {user, setUser} = useContext(DataContext);

  const [anchorEl, setAnchorEl] = useState(null);

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget); // Beállítjuk a menü helyzetét a kattintott elemhez képest
  };

  const handleClose = () => {
    setAnchorEl(null); // Bezárjuk a menüt
  };

  const handleLogout = () => {
    setUser({});
    navigate("/")
  };

  return (
    <Box id='PageHeaderUserDetailsBody' sx={{ display: 'flex', height: '100%', gap: 0.5, }}>
      <Box 
        id='UserInfoBody' 
        sx={{ 
          width: 'auto',
          height: 'auto',
          padding: '4px 8px 8px 8px',
          display: 'flex',
          cursor: 'pointer',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
          gap: 1,
          backgroundColor: '#08979C',
          borderRadius: '0px 0px 8px 8px',
          boxShadow: '0px 2px 2px 0px rgba(0, 0, 0, 0.25)',
          whiteSpace: 'nowrap',
          transition: 'transform 0.2s ease, box-shadow 0.2s ease',
          '&:hover': {
            transform: 'scale(1.03)',
            boxShadow: 'none',
            backgroundColor: '#68C9CC'
          }
        }}
      >
        <Box id='UserInfoHeader'>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: 10, fontWeight: 300, color: '#FFF' }}>
            Bejelentkezve
          </Typography>
        </Box>

        <Box 
          id='UserInfoContent' 
          sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
        >
          <Avatar 
            alt={user.last_name+" "+user.first_name} 
            src="/path/to/logo.jpg" 
            sx={{ 
              width: 32, 
              height: 32, 
              fontSize: 14, 
              color: '#08979C', 
              backgroundColor: '#FFF' 
            }} 
          />
          <Box>                            
            <Typography 
              sx={{ 
                fontFamily: 'Roboto', 
                fontSize: 12, 
                fontWeight: 500, 
                color: '#FFF',
                '@media (max-width:600px)': {
                  display: 'none'
                }
              }}
            >
              {user.last_name+" "+user.first_name}
            </Typography>
            <Typography 
              sx={{ 
                fontFamily: 'Roboto', 
                fontSize: 10, 
                fontWeight: 300, 
                color: '#FFF',
                '@media (max-width:600px)': {
                  display: 'none'
                }
              }}
            >
              {user?.company?.rank_name}
            </Typography>
          </Box>

          {/* Menü ikon */}
          <MenuIcon onClick={handleClick} sx={{ color: '#FFF', cursor: 'pointer' }} />
          
          {/* Legördülő menü ikonokkal */}
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PaperProps={{
              style: {
                backgroundColor: '#68C9CC', // Menü háttérszíne
                color: '#FFF', // Menü szövegszíne
              }
            }}
          >
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <AccountCircleIcon sx={{ color: '#FFF' }} />
              </ListItemIcon>
              Profil
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <ListItemIcon>
                <SettingsIcon sx={{ color: '#FFF' }} />
              </ListItemIcon>
              Beállítások
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <LogoutIcon sx={{ color: '#FFF' }} />
              </ListItemIcon>
              Kijelentkezés
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </Box>
  );
};

export default PageHeaderUserDetailsBody;