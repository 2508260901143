import { Box, Button, IconButton, Grid2 } from '@mui/material';

import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import DeleteIcon from '@mui/icons-material/Delete';

const ChangeFilePics = ({ images, setImages, imageFiles, setImageFiles }) => {
  const handleImageUpload = (index, event) => {
    const file = event.target.files[0];
    if(file) {
      const newImageFiles = [...imageFiles];
      newImageFiles[index] = file;
      setImageFiles(newImageFiles);

      const newImages = [...images];
      newImages[index] = URL.createObjectURL(file); // Ideiglenes URL a kép megjelenítéséhez
      setImages(newImages);
    }
  };

  const handleImageDelete = (index) => {
    const newImageFiles = [...imageFiles];
    newImageFiles[index] = null;
    setImageFiles(newImageFiles);
    
    const newImages = [...images];
    newImages[index] = null;
    setImages(newImages);
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 2 }}>
      <Grid2 container spacing={2}>
        {images.map((image, index) => (
          <Grid2 size={{ xs: 12, md: 4, sm:6 }} key={index}>
            <Box
              sx={{
                position: 'relative',
                width: '100%',
                paddingTop: '100%',
                border: '2px dashed #d9d9d9',
                borderRadius: 2,
                backgroundColor: '#f5f5f5',
                overflow: 'hidden',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {image ? (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  <IconButton
                    onClick={() => handleImageDelete(index)}
                    sx={{
                      position: 'absolute',
                      top: 8,
                      right: 8,
                      backgroundColor: '#fff',
                      color: '#08979C',
                      '&:hover': { backgroundColor: '#f0f0f0' },
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ) : (
                <>
                  <Button
                    variant="contained"
                    component="label"
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      bgcolor: '#08979C',
                      color: '#fff',
                      '&:hover': { bgcolor: '#68C9CC' },
                    }}
                    startIcon={<AddAPhotoOutlinedIcon />}
                  >
                    Feltöltés
                    <input
                      type="file"
                      hidden
                      accept="image/*"
                      onChange={(event) => handleImageUpload(index, event)}
                    />
                  </Button>
                </>
              )}
            </Box>
          </Grid2>
        ))}
      </Grid2>
    </Box>
  );
};

export default ChangeFilePics;