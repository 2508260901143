import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import axios from 'axios';

import * as XLSX from "xlsx";

import { DataContext } from '../../../context/DataContext';
import { calculateReceiptNumber, getCardSum, getReturnSum, getTrafficCashIn, getTrafficCashOut, getTrafficProfit, getTrafficSum, getVatProfit, getVatRateSum } from '../../../services/TrafficCalc';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  IconButton,
  Button,
  Grow,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ChecklistOutlinedIcon from '@mui/icons-material/ChecklistOutlined';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import PointOfSaleOutlinedIcon from '@mui/icons-material/PointOfSaleOutlined';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import AssignmentTurnedInOutlinedIcon from '@mui/icons-material/AssignmentTurnedInOutlined';

import CloseFileDetails from './content/CloseFileDetails';
import CloseFileCassa from './content/CloseFileCassa';
import CloseFilePics from './content/CloseFilePics';
import CloseFileFinalizeModal from './content/CloseFileFinalizeModal';

const CloseFile = () => {
  const {user, setUser, setLoading, setMsg} = useContext(DataContext);

  const currencies = [
    { name: '20.000 Ft', value: 20000 },
    { name: '10.000 Ft', value: 10000 },
    { name: '5.000 Ft', value: 5000 },
    { name: '2.000 Ft', value: 2000 },
    { name: '1.000 Ft', value: 1000 },
    { name: '500 Ft', value: 500 },
    { name: '200 Ft', value: 200 },
    { name: '100 Ft', value: 100 },
    { name: '50 Ft', value: 50 },
    { name: '20 Ft', value: 20 },
    { name: '10 Ft', value: 10 },
    { name: '5 Ft', value: 5 },
  ];

  const [traffic, setTraffic] = useState([]);
  const [fileName, setFileName] = useState("");

  const [round, setRound] = useState(0);
  const [amounts, setAmounts] = useState(Array(currencies.length).fill(0));
  const [total, setTotal] = useState(0);
  const [cancel, setCancel] = useState(0);
  const [comment, setComment] = useState("");

  const [images, setImages] = useState([null, null, null]);
  const [imageFiles, setImageFiles] = useState([null, null, null]);
  
  const [modalOpen, setModalOpen] = useState(false);

  const [expanded, setExpanded] = useState("file");

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const ExcelDateToJSDate = (serial) => {
    let utc_days  = Math.floor(serial - 25569);
    let utc_value = utc_days * 86400;                                        
    let date_info = new Date(utc_value * 1000);

    let fractional_day = serial - Math.floor(serial) + 0.0000001;

    let total_seconds = Math.floor(86400 * fractional_day);

    let seconds = total_seconds % 60;

    total_seconds -= seconds;

    let hours = Math.floor(total_seconds / (60 * 60));
    let minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(date_info.getFullYear(), date_info.getMonth(), date_info.getDate(), hours, minutes, seconds).toLocaleString("hu-HU");
  };

  const handleFileChange = async(e) => {
    if(!e.target.files[0]) {
      return;
    }

    const file = e.target.files[0];

    if(file.name.split(".")[1] !== "XLS") {
      return setMsg({
        color: "error",
        message: "A kiválasztott fájl nem Excel fájl!"
      });
    }

    const data = await file.arrayBuffer();
    const workbook = XLSX.read(data);

    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const jsondata = XLSX.utils.sheet_to_json(worksheet);
    
    if(isExcelFileStructureOk(jsondata)){
      jsondata.map(row => {
        if(row.hasOwnProperty("Kassza")){
          setTraffic(prevFile => [
            ...prevFile,
            {
              date: ExcelDateToJSDate(JSON.stringify(row["Ido"])),
              barcode: JSON.stringify(row["Vonalkód"]),
              item_name: JSON.stringify(row["Megnevezés"]).replaceAll('"', ""),
              receipt_num_1: JSON.stringify(row["Kassza"]).replaceAll('"', ""),
              receipt_num_2: JSON.stringify(row["Bizonylat"])?.replaceAll('"',""),
              quantity: JSON.stringify(row["Mennyiség"]).replaceAll('"', "").replaceAll(" ", "").replaceAll(/[^\d.-]/g, ""),
              unit_price: JSON.stringify(row["Egységár"]),
              unit_value: JSON.stringify(row["Érték"]),
              purchase_price: JSON.stringify(row["Besz.ár"]),
              purchase_value: JSON.stringify(row["Besz.Érték"]),
              vat_rate: JSON.stringify(row["Áfakulcs"]),
              card: row["Fizetési mód"] === "Bankkártya" || row["Fizetési mód"] === "Bankkátya" ? JSON.stringify(row["Érték"]) : 0
            }
          ]);

          setMsg({});
          setFileName(file.name);
          return //setChange(true);
        }
      });
    }
    else {
      return setMsg({
        color: "error",
        message: "A kiválasztott fájl struktúrája nem megfelelő!"
      })
    }
  };

  const isExcelFileStructureOk = (arr) => {
    let ok = true;

    arr.map((row, i) => {
      if(i !== (arr.length-1)){
        if(!row.hasOwnProperty("Ido") || !row.hasOwnProperty("Megnevezés") || 
        !row.hasOwnProperty("Kassza") || !row.hasOwnProperty("Mennyiség") || 
        !row.hasOwnProperty("Egységár") || !row.hasOwnProperty("Érték") || 
        !row.hasOwnProperty("Besz.ár") || !row.hasOwnProperty("Besz.Érték")){
          return ok = false;
        }
      }
    });

    return ok;
  };

  const handleReset = () => {
    setTraffic([]);
    setFileName("");
  };

  const getAccordionStyle = (isDisabled) => ({
    opacity: isDisabled ? 0.5 : 1,
    pointerEvents: isDisabled ? 'none' : 'auto',
    transition: 'opacity 0.3s ease',
  });

  const handleClose = async() => {
    setLoading(true);

    const res = await fetch(apiUrl+"/store/daily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        user_before: null,
        store: user.store,
        operation: 2,
        huf5: Number(amounts[11]),
        huf10: Number(amounts[10]),
        huf20: Number(amounts[9]),
        huf50: Number(amounts[8]),
        huf100: Number(amounts[7]),
        huf200: Number(amounts[6]),
        huf500: Number(amounts[5]),
        huf1000: Number(amounts[4]),
        huf2000: Number(amounts[3]),
        huf5000: Number(amounts[2]),
        huf10000: Number(amounts[1]),
        huf20000: Number(amounts[0]),
        sum: Number(total)
      })
    });

    const data = await res.json();

    if(!data.success){
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    handleSaveTrafficItemized(data.id);
  };

  const handleSaveTrafficItemized = async(dailyId) => {
    const res = await fetch(apiUrl+"/store/traffic/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        store: user.store,
        traffic: traffic
      })
    });

    const data = await res.json();

    if(!data.success) {
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    handleSaveTraffic(dailyId);
  };

  const handleSaveTraffic = async(dailyId) => {
    const res = await fetch(apiUrl+"/store/traffic/sum/save", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        store: user.store,
        operation: 2,
        daily: dailyId,
        from: traffic[0].date,
        to: traffic[traffic.length-1].date,
        daily_traffic_sum: getTrafficSum(traffic),
        cash_in: getTrafficCashIn(traffic),
        cash_out: getTrafficCashOut(traffic),
        sum: (getTrafficSum(traffic) + getTrafficCashIn(traffic) - getTrafficCashOut(traffic) - getReturnSum(traffic)),
        card: getCardSum(traffic),
        round: round,
        // serial_num: serialNum,
        return_sum: getReturnSum(traffic),
        profit: getTrafficProfit(traffic),
        receipt_quantity: calculateReceiptNumber(traffic),
        canceled: 0,
        a_tax: getVatRateSum(traffic, 5),
        b_tax: getVatRateSum(traffic, 18),
        c_tax: getVatRateSum(traffic, 27),
        d_tax: getVatRateSum(traffic, 0),
        e_tax: 0,
        a_tax_profit: getVatProfit(traffic, 5),
        b_tax_profit: getVatProfit(traffic, 18),
        c_tax_profit: getVatProfit(traffic, 27),
        d_tax_profit: getVatProfit(traffic, 0),
        e_tax_profit: 0,
        comment: comment
      })
    });

    const data = await res.json();

    if(!data.success) {
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    uploadImages(data.id);
  };

  const uploadImages = async(trafficItem) => {
    const files = new FormData();
    
    for(let i = 0; i < imageFiles.length; i++) {
      files.append("files", imageFiles[i]);
    }

    axios.post(apiUrl+"/store/traffic/images/"+trafficItem, files)
    .then(res => {
      setUser(prevUser => ({...prevUser, store: null}));

      setLoading(false);

      navigate("/tobacco");
    });
  };

  return (
    <Grow in timeout={800}>
    <Box sx={{ flexGrow: 1, padding: 2, mt: 12 }}>
      {/* Fejléc */}
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <IconButton onClick={() => navigate('/tobacco')}>
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <Typography
            sx={{ fontFamily: 'Roboto', fontSize: '20px', color: '#8C8C8C', fontWeight: '600' }}
          >
            Napzárás
          </Typography>
        </Box>
      </Box>

      {/* Fájl feltöltés */}
      <Accordion
        expanded={expanded === "file"}
        onChange={() => setExpanded(expanded !== "file" ? "file" : false)}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <FileUploadOutlinedIcon sx={{ color: '#8c8c8c' }} />
            <Typography sx={{ fontWeight: '600', color: '#8c8c8c' }}>Forgalmi adatok feltöltése</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                gap: 2,
                mt: 2,
                p: 2,
                border: '1px solid #d9d9d9',
                borderRadius: 2,
                width: '100%',
                maxWidth: 400,
              }}
            >
              {fileName === "" ? (
                <>
                  <Typography sx={{ fontSize: '14px', fontWeight: '400', color: '#D9D9D9' }}>
                    Válaszd ki a fájlt
                  </Typography>
                  <IconButton
                    component="label"
                    sx={{
                      bgcolor: '#08979C',
                      color: '#fff',
                      fontSize: '24px',
                      '&:hover': { bgcolor: '#68C9CC' },
                    }}
                  >
                    <FileUploadOutlinedIcon />
                    <input type="file" hidden onChange={handleFileChange} />
                  </IconButton>
                </>
              ) : (
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                  <Typography
                    sx={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: '#08979C',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      maxWidth: '70%',
                    }}
                    title={fileName}
                  >
                    {fileName}
                  </Typography>
                  <IconButton
                    onClick={handleReset}
                    sx={{
                      color: '#08979C',
                      '&:hover': { color: '#68C9CC' },
                    }}
                  >
                    <DeleteOutlineIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Adatok ellenőrzése */}
      <Accordion
        expanded={expanded === "stats"}
        onChange={() => setExpanded(expanded !== "stats" ? "stats" : false)}
        sx={getAccordionStyle(fileName === "")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2a-content" id="panel2a-header">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <ChecklistOutlinedIcon sx={{ color: '#8c8c8c' }} />
            <Typography sx={{ fontWeight: '600', color: '#8c8c8c' }}>Adatok ellenőrzése</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CloseFileDetails
            traffic={traffic}
            round={round}
            setRound={setRound}
          />
        </AccordionDetails>
      </Accordion>

      {/* Kassza ellenőrzés */}
      <Accordion
        expanded={expanded === "cashtray"}
        onChange={() => setExpanded(expanded !== "cashtray" ? "cashtray" : false)}
        sx={getAccordionStyle(fileName === "")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <PointOfSaleOutlinedIcon sx={{ color: '#8c8c8c' }} />
            <Typography sx={{ fontWeight: '600', color: '#8c8c8c' }}>Kassza ellenőrzés</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CloseFileCassa
            traffic={traffic}
            round={round}
            cancel={cancel}
            setCancel={setCancel}
            comment={comment}
            setComment={setComment}
            amounts={amounts}
            setAmounts={setAmounts}
            total={total}
            setTotal={setTotal}
            currencies={currencies}
          />
        </AccordionDetails>
      </Accordion>

      {/* Képek feltöltése */}
      <Accordion
        expanded={expanded === "pics"}
        onChange={() => setExpanded(expanded !== "pics" ? "pics" : false)}
        sx={getAccordionStyle(fileName === "")}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel4a-content" id="panel4a-header">
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <AddAPhotoOutlinedIcon sx={{ color: '#8c8c8c' }} />
            <Typography sx={{ fontWeight: '600', color: '#8c8c8c' }}>Képek feltöltése</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <CloseFilePics
            images={images}
            setImages={setImages}
            imageFiles={imageFiles}
            setImageFiles={setImageFiles}
          />
        </AccordionDetails>
      </Accordion>

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', padding: 2 }}>
        <Button
          variant="contained"
          sx={{
            bgcolor: fileName !== "" ? '#08979C' : '#d9d9d9',
            color: fileName !== "" ? '#fff' : '#8c8c8c',
            '&:hover': fileName !== "" ? { bgcolor: '#68C9CC' } : {},
          }}
          disabled={fileName === ""}
          startIcon={<AssignmentTurnedInOutlinedIcon />}
          onClick={() => setModalOpen(true)}
        >
          Véglegesítés
        </Button>
      </Box>

      {/* Modal */}
      <CloseFileFinalizeModal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onConfirm={handleClose}
      />
    </Box>
    </Grow>
  );
};

export default CloseFile;