import { Box, Grid2, Grow, TextField, Typography } from '@mui/material';

import { getCardSum, getTrafficCashIn, getTrafficCashOut, getTrafficSum, getReturnSum, getTrafficOnlyCashout } from "../../../../services/TrafficCalc";

import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

import CloseFileDataInputBase from './CloseFileDataInputBase';

const CloseFileDetails = ({ traffic, round, setRound }) => {
  return (
    <Grid2 container spacing={2}>
        <Grow in timeout={800}>
            <Grid2 size={{ xs: 12, md: 4 }}>
                <Box sx={{ display: 'flex', padding: 1 }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', padding: 0.8, backgroundColor: '#08979C', color: '#FFF', borderRadius: 1}}>Bevételek</Typography>  
                </Box>
            
                <CloseFileDataInputBase 
                    label="Napi forgalom"
                    value={getTrafficSum(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Egyéb bevétel"
                    value="0"
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />            

                <CloseFileDataInputBase 
                    label="Készpénz"
                    value={getTrafficCashIn(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Bevételek összesen"
                    value={getTrafficSum(traffic)+getTrafficCashIn(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />
            </Grid2>
        </Grow>

        <Grow in timeout={800}>
            <Grid2 size={{ xs: 12, md: 4 }}>
                <Box sx={{ display: 'flex', padding: 1 }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', padding: 0.8, backgroundColor: '#08979C', color: '#FFF', borderRadius: 1}}>Kiadások</Typography>  
                </Box>

                <CloseFileDataInputBase 
                    label="Sztornó"
                    value="0"
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Visszaárú"
                    value={getReturnSum(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />            

                <CloseFileDataInputBase 
                    label="Egyéb kiadások"
                    value="0"
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Készpénz"
                    value={getTrafficOnlyCashout(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Kiadások összesen"
                    value={getTrafficCashOut(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />
            </Grid2>
        </Grow>

        <Grow in timeout={800}>
            <Grid2 size={{ xs: 12, md: 4 }}>
                <Box sx={{ display: 'flex', padding: 1 }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', padding: 0.8, backgroundColor: '#08979C', color: '#FFF', borderRadius: 1}}>Fiók tartalom</Typography>  
                </Box>

                <CloseFileDataInputBase 
                    label="Kerekítés nélküli fióktartalom"
                    value={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <Box sx={{ mt: 2 }}>
                    <TextField
                        label="Kerekítés összege"
                        fullWidth
                        value={round}
                        onChange={(e) => setRound(e.target.value)}
                        InputProps={{
                            endAdornment: (
                            <DriveFileRenameOutlineOutlinedIcon
                                sx={{ color: '#8c8c8c' }} // Fix szín
                            />
                            ),
                        }}
                        sx={{
                            '& .MuiOutlinedInput-root': {
                                '& fieldset': {
                                    borderColor: '#d9d9d9'
                                },
                                '&:hover fieldset': {
                                    borderColor: '#08979C'
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: '#08979C'
                                },
                                '& input': {
                                    color: '#08979C',
                                    fontWeight: '600'
                                }
                            },
                            '& .MuiInputLabel-root': {
                                color: '#8c8c8c'
                            },
                            '& .MuiInputLabel-root.Mui-focused': {
                                color: '#08979C'
                            }
                        }}
                    />
                </Box>       

                <CloseFileDataInputBase 
                    label="Kerekített fiók tartalom"
                    value={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Készpénz"
                    value={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)-getCardSum(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Bankkártya"
                    value={getCardSum(traffic)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />

                <CloseFileDataInputBase 
                    label="Fizető eszközök összesen"
                    value={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)}
                    icon={<CheckCircleOutlineOutlinedIcon></CheckCircleOutlineOutlinedIcon>}
                />
            </Grid2>
        </Grow>
    </Grid2>
  );
};

export default CloseFileDetails;
