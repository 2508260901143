import React from 'react'

//Mui elemek
import { TextField, InputAdornment } from '@mui/material';

const InputLogIn = ({ label, type = 'text', startIcon, val, change, ...props }) => {
  return (
        <TextField
            label={label}
            type={type}
            variant="standard"
            fullWidth
            margin="normal"
            value={val}
            onChange={change}
            InputProps={{
                startAdornment: startIcon ? (
                <InputAdornment position="start">{startIcon}</InputAdornment>
                ) : null,
                sx: {
                paddingBottom: 1
                },
            }}
            InputLabelProps={{
                sx: {
                marginBottom: 1,
                "&.Mui-focused": {
                    color: "#08979C", // Aktív állapot label szín
                },
                },
            }}
            inputProps={{
                sx: {
                color: "#08979C", // Beviteli szöveg szín aktív állapotban
                },
            }}
            sx={{
                "& .MuiInput-underline:before": {
                    borderBottomColor: "#08979C", // Alapértelmezett alsó vonal szín
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: "#08979C", // Aktív állapot alsó vonal szín
                }
            }}
            {...props}
        />
  );
};

export default InputLogIn
