import React from 'react'

//Mui elemek
import { Typography, Box } from '@mui/material'

//Componensek
import Logo from '../svg/Logo.svg'

const BaseLogo = () => {
  return (

    <Box sx={{ display: 'flex', alignSelf: 'center', gap: 0.5, paddingBottom: 1, paddingLeft: 1 }}>        
        <Box component="img" src={Logo} alt="MyTrafix Solutions" sx={{ width: 40 }} />
        <Typography sx={{ fontFamily: 'Roboto', fontSize: 14, color: '#8c8c8c', lineHeight: '10px', alignSelf: 'flex-end' }}>MyTrafix</Typography>
    </Box>

  )
}

export default BaseLogo
