import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import axios from 'axios';
import { useGoogleLogin } from '@react-oauth/google';

import { DataContext } from '../context/DataContext';
import { getUserCompanies, getUserCompaniesStores, getUserOperations, getUserStores } from '../services/Api';

//mui elemek
import { Box, Typography, Button } from '@mui/material';

//iconok és képek
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import InputLogIn from './InputLogIn';
import GoogleIcon from '@mui/icons-material/Google';
import FacebookIcon from '@mui/icons-material/Facebook';
import AppleIcon from '@mui/icons-material/Apple';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const RegistrationField = ({ onBackClick }) => {
  const {setUser, setOps, setStores, setLoading, setMsg} = useContext(DataContext);

  const [googleUser, setGoogleUser] = useState({});

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pw, setPw] = useState("");
  const [pwAgain, setPwAgain] = useState("");

  const navigate = useNavigate(); // Navigációs hook használata

  const params = useParams();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if(JSON.stringify(googleUser) !== "{}") {
      axios
        .get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${googleUser.access_token}`, {
          headers: {
            Authorization: `Bearer ${googleUser.access_token}`,
            Accept: 'application/json'
          }
        })
        .then((res) => {
          handleNextGoogle(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [googleUser]);

  const googleLogin = useGoogleLogin({
    onSuccess: res => setGoogleUser(res),
    onError: () => console.log("login failed")
  });

  const handleNextGoogle = async(info) => {
    const res = await fetch(apiUrl+"/user/email/"+info.email, {
       method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    if(data.user.length !== 0) {
      return setMsg({
        color: "error",
        message: "Ez az email cím használatban van"
      });
    }

    registerGoogle(info);
  };

  const registerGoogle = async(info) => {
    const res = await fetch(apiUrl+"/user/register/google", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: info,
        token: params?.id
      })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    getUserStores(data.user.id).then(userStores => {
      getUserCompanies(data.user.id).then(userCompanies => {
        if(userCompanies.length === 0) {
          setUser({...data.user});
          setMsg({});

          return navigate('/new-organization'); // Navigálás a NewOrganization oldalra
        }
        else {
          setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
          setMsg({});

          getUserCompaniesStores(data.user.id).then(storeData => {
            return setStores(storeData);
          });

          // getModules(userCompanies[0].id).then(modules => setModuleTab(modules.length+1));

          setMsg({});
          return navigate("/tobacco");
        }
      });
    });
  };

  const handleCheckEmail = async() => {
    const res = await fetch(apiUrl+"/user/email/"+email, {
        method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return console.log(data.message)
    }

    if(data.user.length !== 0) {
      return console.log("Ez az email cím használatban van")
    }

    register();
  };

  const register = async() => {
    if(pw !== pwAgain) {
      // nem egyeznek a jelszavak
      return;
    }

    setLoading(true);

    const res = await fetch(apiUrl+"/user/register", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: {
          last: lastName,
          first: firstName,
          email: email,
          pass: pw
        },
        token: params?.id
      })
    });

    const data = await res.json();

    if(!data.success) {
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    getUserStores(data.user.id).then(userStores => {
      getUserCompanies(data.user.id).then(userCompanies => {
        if(userCompanies.length === 0) { // ha nem meghívóval regisztrált
          setUser({...data.user});
          setMsg({});
          setLoading(false);
          return navigate('/new-organization'); // Navigálás a NewOrganization oldalra
        }
        else {
          setUser({...data.user, stores: userStores, company: userCompanies[0], companies: userCompanies});
          setMsg({});

          getUserCompaniesStores(data.user.id).then(storeData => {
              return setStores(storeData);
          });

          getUserOperations(data.user.id).then(opsData => {
              setOps(opsData.filter(item => Number(item.company) === Number(userCompanies[0].id)));
          });

          setMsg({});
          setLoading(false);

          return navigate("/tobacco");
        }
      });
    });
  };

  return (
    <Box
      id="Registration Field"
    >
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'center',justifyContent:'space-between', backgroundColor: '#048489', padding:2, boxSizing:'border-box' }}>
        <HowToRegIcon sx={{ color: '#fff', fontSize: 32 }}></HowToRegIcon>
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '200', color: '#fff' }}>
          Regisztráció
        </Typography>
        <HighlightOffIcon onClick={onBackClick} sx={{ color: '#fff', fontSize: 32, cursor: 'pointer', opacity: '0.4', '&:hover': { opacity: '1' } }}></HighlightOffIcon>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1, padding: 2, alignItems: 'center',justifyContent: 'center', marginTop: 1, boxSizing: 'border-box' }}>
        <Box
          onClick={googleLogin}
          sx={{ display: 'flex', opacity: '0.4', gap: 3, cursor: 'pointer', border: '0.5px solid #000', padding: 2, borderRadius: 1, width: '100%', boxSizing: 'border-box', '&:hover': { backgroundColor: '#048489', borderColor: '#68C9CC', transform: 'scale(1.03)', color: '#FFF', opacity: '1' }, }}
        >
          <GoogleIcon></GoogleIcon>
          <Typography>Regisztráció Google fiókkal</Typography>
        </Box>

        <Box sx={{ display: 'flex', opacity: '0.4', gap: 3, cursor: 'pointer', border: '0.5px solid #000', padding: 2, borderRadius: 1, width: '100%', boxSizing: 'border-box', '&:hover': { backgroundColor: '#048489', borderColor: '#68C9CC', transform: 'scale(1.03)', color: '#FFF', opacity: '1' }, }}>
          <FacebookIcon></FacebookIcon>
          <Typography>Regisztráció Facebook fiókkal</Typography>
        </Box>

        <Box sx={{ display: 'flex', opacity: '0.4', gap: 3, cursor: 'pointer', border: '0.5px solid #000', padding: 2, borderRadius: 1, width: '100%', boxSizing: 'border-box', '&:hover': { backgroundColor: '#048489', borderColor: '#68C9CC', transform: 'scale(1.03)', color: '#FFF', opacity: '1' }, }}>
          <AppleIcon></AppleIcon>
          <Typography>Regisztráció Apple fiókkal</Typography>
        </Box>

      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2 }}>
        <Box sx={{ display: 'flex', gap:2}}>
          <InputLogIn
            label="Vezetéknév"
            type="text"
            startIcon={<EmailIcon />}
            val={lastName}
            change={(e) => setLastName(e.target.value)}
          />

          <InputLogIn
            label="Keresztnév"
            type="text"
            startIcon={<EmailIcon />}
            val={firstName}
            change={(e) => setFirstName(e.target.value)}
          />
        </Box>

        <Box sx={{ display: 'flex', gap:2}}>
          <InputLogIn
            label="Email"
            type="email"
            startIcon={<EmailIcon />}
            val={email}
            change={(e) => setEmail(e.target.value)}
          />
        </Box>

        <Box sx={{ display: 'flex', gap:2}}>
          <InputLogIn
            label="Jelszó"
            type="password"
            startIcon={<LockIcon />}
            val={pw}
            change={(e) => setPw(e.target.value)}
          />

          <InputLogIn
            label="Jelszó megerősítés"
            type="password"
            startIcon={<LockIcon />}
            val={pwAgain}
            change={(e) => setPwAgain(e.target.value)}
          />
        </Box>        
      </Box>

      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'flex-end', gap: 1, padding: 2, boxSizing: 'border-box' }}>
        <Button
          variant="outlined"
          color="primary"
          onClick={onBackClick}
          sx={{
            mt: 2,
            border: '0.5px solid #68C9CC',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: '300',
            color: '#68C9CC',
            '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', border: '0.5px solid #048489', transform: 'scale(1.03)' },
          }}
        >
          Mégse
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCheckEmail} // Navigáció a Véglegesítés gombra kattintva
          sx={{
            mt: 2,
            backgroundColor: '#048489',
            fontFamily: 'Roboto',
            fontSize: '12px',
            fontWeight: '300',
            '&:hover': { backgroundColor: '#68C9CC', transform: 'scale(1.03)' },
          }}
        >
          Véglegesítés
        </Button>
      </Box>
    </Box>
  );
};

export default RegistrationField;