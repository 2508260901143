import { createRef, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { DataContext } from '../../context/DataContext';

import {
  Box,
  Typography,
  TextField,
  Grid2,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Grow,
  Avatar,
  IconButton,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TimeSettingModal from './content/TimeSettingModal';

const DayOpen = () => {
  const {user, setUser, setLoading, setMsg} = useContext(DataContext);

  const currencies = [
    { name: '20.000 Ft', value: 20000 },
    { name: '10.000 Ft', value: 10000 },
    { name: '5.000 Ft', value: 5000 },
    { name: '2.000 Ft', value: 2000 },
    { name: '1.000 Ft', value: 1000 },
    { name: '500 Ft', value: 500 },
    { name: '200 Ft', value: 200 },
    { name: '100 Ft', value: 100 },
    { name: '50 Ft', value: 50 },
    { name: '20 Ft', value: 20 },
    { name: '10 Ft', value: 10 },
    { name: '5 Ft', value: 5 },
  ];

  const [amounts, setAmounts] = useState(Array(currencies.length).fill(0));
  const [total, setTotal] = useState(0);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const inputRefs = Array(currencies.length)
    .fill(null)
    .map(() => createRef());

  const navigate = useNavigate();

  const location = useLocation();

  const { id, name, address, initials, color } = location.state || {};

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleInputChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = Number(value) || 0;
    setAmounts(newAmounts);

    const newTotal = newAmounts.reduce(
      (sum, amount, i) => sum + amount * currencies[i].value,
      0
    );
    setTotal(newTotal);
  };

  const handleKeyPress = (index, event) => {
    if (event.key === 'Enter') {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    } else if (event.key === 'Backspace') {
      if (amounts[index] === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  const handleGetDayClose = async() => {
    const res = await fetch(apiUrl+"/store/prevday", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            store: id
        })
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    if(data.prev){
      setAmounts([
        data.prev.huf_5_pcs,
        data.prev.huf_10_pcs,
        data.prev.huf_20_pcs,
        data.prev.huf_50_pcs,
        data.prev.huf_100_pcs,
        data.prev.huf_200_pcs,
        data.prev.huf_500_pcs,
        data.prev.huf_1000_pcs,
        data.prev.huf_2000_pcs,
        data.prev.huf_5000_pcs,
        data.prev.huf_10000_pcs,
        data.prev.huf_20000_pcs
      ]);

      setTotal(data.prev.sum);
    }
  };

  const handleDayOpen = async(shiftEnd) => {
    setLoading(true);

    const res = await fetch(apiUrl+"/store/daily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        user_before: null,
        shiftEnd: shiftEnd,
        store: id,
        operation: 1,
        huf5: Number(amounts[0]),
        huf10: Number(amounts[1]),
        huf20: Number(amounts[2]),
        huf50: Number(amounts[3]),
        huf100: Number(amounts[4]),
        huf200: Number(amounts[5]),
        huf500: Number(amounts[6]),
        huf1000: Number(amounts[7]),
        huf2000: Number(amounts[8]),
        huf5000: Number(amounts[9]),
        huf10000: Number(amounts[10]),
        huf20000: Number(amounts[11]),
        sum: Number(total)
      })
    });

    const data = await res.json();

    if(!data.success) {
      console.log(data.message)
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    setUser(prevUser => ({...prevUser, store: id}));

    // socket.emit("store_op", {id: store.id});

    setLoading(false);

    setMsg({
      color: "success",
      message: "Sikeres művelet!"
    });

    navigate("/tobacco");
  };

  return (
    <Grow in timeout={800}>
      <Box
        sx={{
          flexGrow: 1,
          padding: 1,
          maxWidth: '1000px',
          alignItems: 'center',
          margin: 'auto',
          mt: '80px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Kiválasztott Shop megjelenítése */}
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            padding: 1,
            width: '100%',
            boxSizing: 'border-box',
          }}
        >
          {/* Fejléc vissza gombbal */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            <IconButton onClick={() => navigate('/tobacco')}>
              <ArrowBackIcon
                sx={{
                  color: '#8c8c8c',
                  fontSize: '18px',
                  '&:hover': { color: '#000' },
                }}
              />
            </IconButton>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '14px',
                fontWeight: '500',
                color: '#8c8c8c',
              }}
            >
              Napnyitás
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Avatar
              sx={{
                bgcolor: color || '#ccc',
                width: 32,
                height: 32,
                fontSize: '10px',
              }}
            >
              {initials || '?'}
            </Avatar>
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography
                sx={{
                  fontSize: '12px',
                  fontWeight: 'bold',
                  color: '#08979C',
                }}
              >
                {name || 'Ismeretlen Eladótér'}
              </Typography>
              <Typography
                sx={{
                  fontSize: '10px',
                  color: '#8c8c8c',
                }}
              >
                {address || 'Nincs megadva cím'}
              </Typography>
            </Box>
          </Box>
        </Box>

        {/* Pénzegységek mezői */}
        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 12, md: 8 }}>
            <Grid2 size={12} sx={{ boxShadow: 2, borderRadius: 1, padding: 2 }}>
              <Grid2 container spacing={2}>
                {currencies.map((currency, index) => (
                  <Grid2
                    size={3}
                    key={index}
                    sx={{
                      opacity: amounts[index] === 0 ? 0.3 : 1,
                      transition: 'opacity 0.3s ease',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {currency.name}
                      </Typography>
                      <TextField
                        size="small"
                        value={amounts[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyPress(index, e)}
                        onFocus={(e) => e.target.select()}
                        variant="outlined"
                        inputRef={inputRefs[index]}
                        inputProps={{
                          min: 0,
                          inputMode: 'numeric',
                        }}
                        sx={{
                          width: '70px',
                          color: '#08979C',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#d9d9d9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#08979C',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#08979C',
                            },
                          },
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            color: '#08979C',
                            fontSize: '14px',
                            fontWeight: '500',
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: '10px', color: '#8c8c8c' }}>
                        {amounts[index] * currency.value} Ft
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
              </Grid2>
            </Grid2>
          </Grid2>

          {/* Összegző rész */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Grid2 size={12} sx={{ boxShadow: 2, borderRadius: 1, height: '100%' }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                  padding: 2,
                  height: '100%',
                }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, marginBottom: 3 }}>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleOpenModal}
                    sx={{
                      bgcolor: '#08979C',
                      fontSize: '12px',
                      fontWeight: '300',
                      '&:hover': { bgcolor: '#68C9CC' },
                    }}
                  >
                    Napnyitás
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={handleGetDayClose}
                    sx={{
                      backgroundColor: '#fff',
                      border: '0.5px solid #08979C',
                      color: '#08979C',
                      fontSize: '12px',
                      fontWeight: '300',
                      '&:hover': { bgcolor: '#68C9CC', color: '#FFF' },
                    }}
                  >
                    Előző napi záróérték másolása
                  </Button>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={() => {
                      setAmounts(Array(currencies.length).fill(0));
                      setTotal(0);
                    }}
                    sx={{
                      backgroundColor: '#fff',
                      border: '0.5px solid #08979C',
                      color: '#08979C',
                      fontSize: '12px',
                      fontWeight: '300',
                      '&:hover': { bgcolor: '#68C9CC', color: '#FFF' },
                    }}
                  >
                    Számlálók visszaállítása
                  </Button>
                </Box>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="summary-content"
                    id="summary-header"
                    sx={{ bgcolor: '#f5f5f5', borderRadius: 1 }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                        width: '100%',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '10px',
                          fontWeight: '300',
                        }}
                      >
                        Összesen:
                      </Typography>
                      <Typography
                        sx={{
                          fontFamily: 'Roboto',
                          fontSize: '14px',
                          fontWeight: '500',
                        }}
                      >
                        {total} Ft
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {currencies.map(
                      (currency, index) =>
                        amounts[index] > 0 && (
                          <Grid2
                            container
                            key={index}
                            spacing={1}
                            sx={{
                              marginBottom: 1,
                              borderBottom: '0.5px solid #8c8c8c',
                              padding: 0.5,
                            }}
                          >
                            <Grid2 size={4}>
                              <Typography
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontSize: '10px',
                                  fontWeight: '300',
                                }}
                              >
                                {currency.name}
                              </Typography>
                            </Grid2>
                            <Grid2 size={4}>
                              <Typography
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontSize: '10px',
                                  fontWeight: '300',
                                }}
                              >
                                {amounts[index]} db
                              </Typography>
                            </Grid2>
                            <Grid2 size={4}>
                              <Typography
                                sx={{
                                  fontFamily: 'Roboto',
                                  fontSize: '10px',
                                  fontWeight: '300',
                                }}
                              >
                                {amounts[index] * currency.value} Ft
                              </Typography>
                            </Grid2>
                          </Grid2>
                        )
                    )}
                  </AccordionDetails>
                </Accordion>
              </Box>
            </Grid2>
          </Grid2>
        </Grid2>
        <TimeSettingModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          handleDayOpen={handleDayOpen}
        />
      </Box>
    </Grow>
  );
};

export default DayOpen;