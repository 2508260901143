import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DigitalClock } from '@mui/x-date-pickers/DigitalClock';
import dayjs from 'dayjs';

const TimeSettingModal = ({ open, handleClose, handleDayOpen }) => {
  const [selectedTime, setSelectedTime] = useState(dayjs());

  const handleTimeChange = (newValue) => {
    setSelectedTime(newValue);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: 300,
        }}
      >
        <Typography variant="body2" sx={{ mb: 2 }}>
          Add meg a műszak váltás / zárás időpontját:
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DigitalClock
            value={selectedTime}
            onChange={handleTimeChange}
            ampm={false} // 24 órás formátum
          />
        </LocalizationProvider>
        <Button
          fullWidth
          variant="contained"
          onClick={() => handleDayOpen(selectedTime)}
          sx={{
            bgcolor: '#08979C',
            color: '#fff',
            fontSize: '14px',
            mt: 2,
            '&:hover': { bgcolor: '#68C9CC' },
          }}
        >
          Véglegesítés
        </Button>
      </Box>
    </Modal>
  );
};

export default TimeSettingModal;