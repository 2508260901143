import { useState, useEffect, useContext } from 'react';

import { DataContext } from "../../../../context/DataContext";

import { useWindowSize } from 'react-use';
import Confetti from 'react-confetti';
import { Box, Typography, LinearProgress, Accordion, AccordionSummary, AccordionDetails, Avatar, Rating, Button, Tooltip } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import EventElement from './content/EventElement';

const ShopEvents = () => {
  const {user, stores, setLoading, setMsg} = useContext(DataContext);

  const [targetTime, setTargetTime] = useState(new Date());

  const [startTime, setStartTime] = useState(new Date()); // Az oldal betöltésekor rögzítjük a kezdési időt
  const [timeLeft, setTimeLeft] = useState(Math.max(targetTime - startTime, 0));
  const [progress, setProgress] = useState(0);
  const [showConfetti, setShowConfetti] = useState(false); // Confetti állapot
  const [rating, setRating] = useState(null); // Az értékelés állapota
  const [confettiShown, setConfettiShown] = useState(false); // Egyszeri futtatás állapota

  const { width, height } = useWindowSize(); // Az ablak méretének lekérése a confettihez

  // Shop adatok
  const [shop, setShop] = useState({});

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if(user.store && stores.length !== 0) {
      getLastStatus();
      setShop(stores.filter(item => item.id === user.store)[0]);
    }
  }, [stores]);

  useEffect(() => {
    const totalDuration = targetTime - startTime; // A teljes időtartam a kezdéstől a célidőig

    if (totalDuration <= 0) {
      setProgress(100);
      setTimeLeft(0);
      return;
    }

    const interval = setInterval(() => {
      const now = new Date().getTime();
      const remaining = Math.max(targetTime - now, 0);

      setTimeLeft(remaining);

      const progressValue = ((totalDuration - remaining) / totalDuration) * 100;
      setProgress(progressValue);

      if (remaining === 0 && !confettiShown) {
        clearInterval(interval);
        setShowConfetti(true); // Mutassuk a confettit
        setConfettiShown(true); // Jelöljük, hogy az animáció lefutott
      }
    }, 1000); // Frissítés másodpercenként

    return () => clearInterval(interval); // Tisztítás
  }, [targetTime, startTime, confettiShown]);

  const getLastStatus = async() => {
    setLoading(true);

    const res = await fetch(apiUrl+"/store/status/last/"+user.store, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    const newStart = new Date().setHours(Number(data.last.start.split(":")[0]), Number(data.last.start.split(":")[1]), Number(data.last.start.split(":")[2]), 0);
    const newTarget = new Date().setHours(Number(data.last.shift_end.split(":")[0]), Number(data.last.shift_end.split(":")[1]), Number(data.last.shift_end.split(":")[2]), 0);

    setTargetTime(newTarget);
    setStartTime(newStart);

    setTimeLeft(Math.max(newTarget - newStart, 0));

    setLoading(false);
  };

  const handleRatingSubmit = () => {
    console.log(`Értékelés: ${rating} csillag`); // Az értékelést itt lehet beküldeni a backendbe
    setShowConfetti(false); // Bezárjuk a confetti-t és az üzenetet
  };

  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const imageExists = (url) => {
    const http = new XMLHttpRequest();

    http.open("HEAD", url, false);
    http.send();

    return http.status !== 404;
  };

  return (
    <Box sx={{ flexGrow: 1, position: 'relative' }}>
      {/* Confetti animáció, ha lejár az idő */}
      {showConfetti && (
        <>
          <Confetti width={width} height={height} />
          <Box
            sx={{
              position: 'fixed',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'rgba(0, 0, 0, 0.7)',
              padding: 3,
              borderRadius: 2,
              textAlign: 'center',
              zIndex: 9999,
              color: '#fff',
            }}
          >
            <Typography variant="h4" sx={{ fontWeight: 'bold', mb: 2 }}>
              Műszak vége!
            </Typography>
            <Typography variant="h5" sx={{ mb: 2 }}>
              Köszönöm a munkád!
            </Typography>
            <Typography variant="body2" sx={{ mb: 2 }}>
              Kérjük, értékeld a napodat:
            </Typography>
            {/* Csillagos értékelő */}
            <Box sx={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', padding: 2, boxSizing: 'border-box', borderRadius: 2, backgroundColor: '#fff'}}>
                <Rating
                value={rating}
                onChange={(event, newValue) => setRating(newValue)}
                size="large"
                sx={{ color: '#FFD700'}}
                />  
            </Box>

            <Button
              onClick={handleRatingSubmit}
              variant="contained"
              sx={{
                bgcolor: '#08979C',
                color: '#fff',
                margin: 2,
                '&:hover': { bgcolor: '#68C9CC' },
              }}
              disabled={rating === null} // Csak akkor engedélyezett, ha kiválasztott egy csillagot
            >
              Értékelés beküldése
            </Button>
          </Box>
        </>
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 1, paddingRight: 1,}}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            {/* Shop információ bal oldalon */}
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Avatar sx={{ bgcolor: shop?.color_code_2, width: 32, height: 32, fontSize: '10px' }}>
                {shop?.name?.[0].toUpperCase()}
              </Avatar>
              <Box sx={{ display: 'flex', flexDirection: 'column',}}>
                <Typography sx={{ fontSize: '12px', fontWeight: 'bold', color: '#8c8c8c' }}>
                  {shop?.name}
                </Typography>
                <Typography sx={{ fontSize: '10px', color: '#8c8c8c' }}>
                  {shop?.address}
                </Typography>
              </Box>
            </Box>

            {/* Időzítő jobbra igazítva */}
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Tooltip
                title={user.last_name+" "+user.first_name}
                arrow
              >
                <Avatar
                  src={imageExists(user.profile_pic ? apiUrl+"/"+user.profile_pic : null)}// Ide helyezd a hölgy profilképének URL-jét
                  sx={{ 
                    width: 32, 
                    height: 32,
                    objectFit: 'cover', // A kép arányainak megtartása
                    objectPosition: 'center', // A kép pozíciójának középre igazítása
                  }}
                />
              </Tooltip>

              <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#08979C', padding: 1 }}>
                  {formatTime(timeLeft)}
              </Typography>
            </Box>


        </Box>
        <Box sx={{ display: 'flex', width: '100%'}}>
            {/* LinearProgress hozzáadása az AccordionSummary-hez */}
            <LinearProgress
                variant="determinate"
                value={progress}
                sx={{
                width: '100%',
                mt: 1,
                height: 3,
                borderRadius: 4,
                '& .MuiLinearProgress-bar': {
                    backgroundColor: '#08979C',
                },
                }}
            />
        </Box>

        </Box>
        </AccordionSummary>
        <AccordionDetails>
          <EventElement></EventElement>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ShopEvents;