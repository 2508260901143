import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from '../../../context/DataContext';
import { getUserCompanies, getUserOperations } from '../../../services/Api';

import { Box, Typography, Button } from '@mui/material';

const RegSummarize = ({ companyData, selectedPackage }) => {
  const {user, setUser, setLoading, setMsg, setStores, setOps} = useContext(DataContext);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFinalize = async() => {
    setLoading(true);

    const res = await fetch(apiUrl+"/company/create", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        user: user.id,
        company: {
          name: companyData.companyName,
          tax: companyData.taxNumber,
          address: companyData.headquarters,
          bank: companyData.bankAccount
        },
        companyType: selectedPackage
      })
    });

    const data = await res.json();

    if(!data.success) {
      console.log(data.message)
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    getUserCompanies(user.id).then(userCompanies => {
      setUser(prev => ({...prev, company: userCompanies.filter(item => item.id === data.newCompany.id)[0], companies: userCompanies}));

      setStores([]);

      getUserOperations(user.id).then(data => {
        setOps(data);
      });

      // getModules(userCompanies.filter(item => item.id === data.newCompany.id)[0].id).then(modules => setModuleTab(modules.length+1));
      
      setMsg({
          color: "success",
          message: "Sikeresen létrehoztad a szervezetet!"
      });

      let packageKey;
      if (selectedPackage === 3) packageKey = "Free";
      if (selectedPackage === 1) packageKey = "Tobacco";
      if (selectedPackage === 2) packageKey = "Apartman";

      navigate('/app', { state: { selectedPackage: packageKey } });

      setLoading(false);
    });
};

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2, padding: 3 }}>
      <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: '#08979C' }}>
        Összegzés
      </Typography>
      <Typography sx={{ fontSize: '16px', color: '#8c8c8c' }}>
        Kiválasztott cég: <strong>{companyData.companyName}</strong>
      </Typography>
      <Typography sx={{ fontSize: '16px', color: '#8c8c8c' }}>
        Kiválasztott csomag: <strong>{selectedPackage}</strong>
      </Typography>
      <Button variant="contained" color="primary" onClick={handleFinalize}>
        Véglegesítés
      </Button>
    </Box>
  );
};

export default RegSummarize;