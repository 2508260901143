import React from 'react';

//Mui elemek
import { Modal, Slide, Box, Typography, Button, TextField, Grid2 } from '@mui/material';

//iconok és képek
import AppsIcon from '@mui/icons-material/Apps';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import VisszaIcon from '@mui/icons-material/ArrowBackIosOutlined';

//componensek
import SlideBarMenuElement from '../../components/SlideBarMenuElement';

const MenuModal = ({ 
    open, 
    onClose, 
    slideDirection, 
    isSmallScreen, 
    selectedMenu, 
    onBack, 
    onSearchChange, 
    searchTerm, 
    filteredMenuItems, 
    handleMenuClick 
}) => {
    
    return (
        <Modal 
            open={open} 
            onClose={onClose} 
            sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                height: isSmallScreen ? '100vh' : 'auto' 
            }}
        >
            <Slide direction={slideDirection} in={open} timeout={500}>
                <Box 
                    sx={{ 
                        backgroundColor: '#FFF', 
                        borderRadius: 1, 
                        width: '80vh', 
                        maxWidth: 800, 
                        margin: 'auto', 
                        marginBottom: 5, 
                        height: isSmallScreen ? '100vh' : '90vh', 
                        overflowY: 'auto' 
                    }}
                >
                    <Box 
                        sx={{ 
                            backgroundColor: '#08979C', 
                            display: 'flex', 
                            width: '100%', 
                            justifyContent: 'space-between', 
                            alignItems: 'center', 
                            padding: 1, 
                            boxSizing: 'border-box', 
                            color: '#FFF' 
                        }}
                    >
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <AppsIcon sx={{ fontSize: 40 }} />
                            <Typography>Műveletek és alkalmazások</Typography>
                        </Box>
                        <HighlightOffIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
                    </Box>
                    <Box sx={{ padding: '0px 8px 8px 8px', backgroundColor: '#FFF' }}>
                        {selectedMenu ? (
                            <Box>
                                <Box sx={{ display: 'flex', width: '100%', height: '60px', marginTop: 3 }}>
                                    <Button 
                                        onClick={onBack} 
                                        sx={{ 
                                            marginBottom: 2, 
                                            display: 'flex', 
                                            gap: 2, 
                                            borderRadius: 2, 
                                            color: '#8c8c8c' 
                                        }}
                                    >
                                        <VisszaIcon />
                                        <Typography>Vissza műveleti csoportokhoz</Typography>
                                    </Button>
                                </Box>
                                {selectedMenu.content}
                            </Box>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', width: '100%', height: '60px', marginTop: 2 }}>
                                    <TextField
                                        variant="outlined"
                                        placeholder="Keresés az alkalmazások között"
                                        size="medium"
                                        onChange={onSearchChange}
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                fontFamily: 'Roboto',
                                                fontSize: 12,
                                                fontWeight: 300,
                                                '& fieldset': { borderColor: '#8c8c8c' },
                                                '&:hover fieldset': { borderColor: '#68C9CC' },
                                                '&.Mui-focused fieldset': { borderColor: '#68C9CC' }
                                            },
                                            '& .MuiInputBase-input': { color: '#000' }
                                        }}
                                    />
                                </Box>
                                <Grid2 container spacing={2} padding={2}>
                                    {filteredMenuItems.map((item, index) => (
                                        <Grid2
                                            item
                                            size={{ xs: 6, sm: 4, md: 4 }}
                                            key={index}
                                            sx={{ 
                                                opacity: !item.text.toLowerCase().includes(searchTerm) ? 0.3 : 1 
                                            }}
                                        >
                                            <SlideBarMenuElement
                                                icon={React.cloneElement(item.icon, {
                                                    sx: {
                                                        fontSize: 32,
                                                        color: item.text.toLowerCase().includes(searchTerm) && searchTerm ? '#08979C' : 'inherit'
                                                    }
                                                })}
                                                text={item.text}
                                                onClick={() => handleMenuClick(item)}
                                            />
                                        </Grid2>
                                    ))}
                                </Grid2>
                            </>
                        )}
                    </Box>
                </Box>
            </Slide>
        </Modal>
    );
};

export default MenuModal;
