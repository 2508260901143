import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from '../../../../../context/DataContext';

import { Box, Typography, Avatar, IconButton, Grow } from '@mui/material';

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import LockIcon from '@mui/icons-material/Lock';
import PersonIcon from '@mui/icons-material/Person';

const ShopElement = ({ id, name, address, initials, color, viewState }) => {
  const {user, setMsg} = useContext(DataContext);

  const [stat, setStat] = useState({});
  const [beforeStat, setBeforeStat] = useState({});
  
  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getStoreStatus();
  }, []);

  const getStoreStatus = async() => {
    const res = await fetch(apiUrl+"/store/status/"+id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "#F58E8E",
        message: data.message
      });
    }

    setStat(data.stat);

    if(data.stat.operation === 3){
      getStoreBeforeStatus();
    }
  };

  const getStoreBeforeStatus = async() => {
    const res = await fetch(apiUrl+"/store/status/before/"+id, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      return setMsg({
        color: "#F58E8E",
        message: data.message
      });
    }

    setBeforeStat(data.stat);
  };

  // Dinamikusan rendereljük az akciókat a viewState alapján
  const renderAction = () => {
    if(stat.operation === 2 && user.stores.filter(s => s.id === id).length !== 0 && user.store === null) {
      return (
        <Box
          onClick={() =>
            navigate('/dayopen', {
              state: { id, name, address, initials, color }, // Adatok átadása a DayOpen nézetnek
            })
          }
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'relative',
            '&:hover .hover-text': {
              opacity: 1,
              transform: 'translateX(0)',
            },
          }}
        >
          <Typography
            className="hover-text"
            sx={{
              opacity: 0,
              transform: 'translateX(-10px)',
              transition: 'opacity 0.3s ease, transform 0.3s ease',
              fontSize: '12px',
              color: '#08979C',
              cursor: 'pointer',
            }}
          >
            Napnyitás
          </Typography>
          <IconButton
            sx={{
              width: 16,
              height: 16,
              bgcolor: '#08979C',
              color: '#fff',
              borderRadius: 1,
              '&:hover': { bgcolor: '#68C9CC' },
            }}
          >
            <PlayArrowIcon sx={{ fontSize: 12 }} />
          </IconButton>
        </Box>
      );
    }
    else if(stat.operation === 2 && user.stores.filter(s => s.id === id).length === 0) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'relative',
            '&:hover .hover-text': {
              opacity: 1,
              transform: 'translateX(0)',
            },
          }}
        >
          <Typography
            className="hover-text"
            sx={{
              opacity: 0,
              transform: 'translateX(-10px)',
              transition: 'opacity 0.3s ease, transform 0.3s ease',
              fontSize: '12px',
              color: '#08979C',
              cursor: 'pointer',
            }}
          >
            Nincs hozzáférésed
          </Typography>
          <IconButton
            sx={{
              width: 16,
              height: 16,
              bgcolor: '#08979C',
              color: '#fff',
              borderRadius: 1,
              '&:hover': { bgcolor: '#68C9CC' },
            }}
          >
            <LockIcon sx={{ fontSize: 12 }} />
          </IconButton>
        </Box>
      );
    }
    else if((stat.operation === 1 || stat.operation === 3) && user.store !== stat.id) {
      return (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1,
            position: 'relative',
            '&:hover .hover-text': {
              opacity: 1,
              transform: 'translateX(0)',
            },
          }}
        >
          <Typography
            className="hover-text"
            sx={{
              opacity: 0,
              transform: 'translateX(-10px)',
              transition: 'opacity 0.3s ease, transform 0.3s ease',
              fontSize: '12px',
              color: '#08979C',
              cursor: 'pointer',
            }}
          >
            {stat.last_name+" "+stat.first_name}
          </Typography>
          <IconButton
            sx={{
              width: 16,
              height: 16,
              bgcolor: '#08979C',
              color: '#fff',
              borderRadius: 1,
              '&:hover': { bgcolor: '#68C9CC' },
            }}
          >
            <PersonIcon sx={{ fontSize: 12 }} />
          </IconButton>
        </Box>
      );
    }
    return null;
  };

  return (
    <Grow in timeout={800}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderRadius: 1,
          padding: 1,
          gap: 1,
          cursor: 'pointer',
          transition: 'transform 0.3s ease, background-color 0.3s ease, box-shadow 0.3s ease',
          '&:hover': {
            backgroundColor: viewState !== 'shopAccess' ? '#f5f5f5' : undefined,
            boxShadow: viewState !== 'shopAccess' ? 1 : undefined,
            transform: viewState !== 'shopAccess' ? 'scale(1.03)' : undefined,
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            opacity: viewState === 'shopAccess' ? 0.5 : 1,
            gap: 1,
          }}
        >
          {/* Avatar */}
          <Avatar sx={{ bgcolor: color, width: 32, height: 32, fontSize: '10px' }}>
            {initials}
          </Avatar>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Name */}
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: '500',
                color: '#8c8c8c',
              }}
            >
              {name}
            </Typography>
            {/* Address */}
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '10px',
                fontWeight: '300',
                color: '#8c8c8c',
              }}
            >
              {address}
            </Typography>
          </Box>
        </Box>
        {/* Dinamikus akció */}
        {renderAction()}
      </Box>
    </Grow>
  );
};

export default ShopElement;