import React from 'react';

//Mui elemek
import { Box, Typography } from '@mui/material';

const SlideBarMenuElement = ({ icon, text, onClick, categoryID }) => {
  return (
    <Box 
      id='SlideBar_menu_element' 
      onClick={() => onClick(categoryID)}
      sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        alignItems: 'center',
        justifyItems: 'center', 
        cursor: 'pointer',
        color: '#8C8C8C', 
        borderRadius: '4px',
        transition: 'transform 0.4s ease, box-shadow 0s ease, color 0s ease',
        boxSizing: 'border-box',
        padding: 3,
        gap: 2,
        '&:hover': {
          transform: 'scale(1.03)',               
          boxShadow: '0px 2px 2px 0px rgba(85, 160, 160, 0.5)', 
          color: '#FFF',
          backgroundColor: '#68C9CC'                     
        }
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>
      <Typography sx={{ fontFamily: 'Roboto', fontSize: 12, fontStyle: 'normal', fontWeight: 300, lineHeight: '22px' }}>
        {text}
      </Typography>
    </Box>
  );
};

export default SlideBarMenuElement;