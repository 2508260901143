import { useContext } from 'react';

import { DataContext } from '../../context/DataContext';

import { Box, Grid2, Grow } from '@mui/material';

import ShopList from './content/shoplist/ShopList';
import OpList from './content/opList/OpList';
import TobaccoShopIn from '../tobaccoShopIn/TobaccoShopIn';

const TobaccoHome = () => {
  const {user} = useContext(DataContext);

  if(user.store) { // ha be van lépve telephelyre
    return (
      <TobaccoShopIn></TobaccoShopIn>
    );
  }
  else {
    return (
      <Box sx={{ flexGrow: 1, padding: 2, mt: 8 }}>
        <Grid2 container spacing={1}>
          <Grow in timeout={800}>
            <Grid2 size={{ xs: 12, md: 4 }}>
              <ShopList />
            </Grid2>
          </Grow>

          <Grow in timeout={1200}>
            <Grid2 size={{ xs:12, md:8}}>
              <OpList />
            </Grid2>
          </Grow>
        </Grid2>
      </Box>
    );
  }
};

export default TobaccoHome;