import { useState, useRef, useEffect, useContext } from 'react';

import { DataContext } from '../../../context/DataContext';

import { Box, Typography, TextField, Grow } from '@mui/material';

import Company from './Company';

const NewOrgVAT = ({ onNext, onOldReg }) => {
  const {setLoading, setMsg} = useContext(DataContext);

  const [vatNumber, setVatNumber] = useState(Array(8).fill('')); // Kezdőállapot üres stringekkel
  const [companyData, setCompanyData] = useState([]);

  const inputRefs = useRef([]); // Input mezők referenciáinak tárolása

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    if(vatNumber.every((digit) => digit !== '')) {
      getCompanyData();
    }
    else {
      setCompanyData([]);
    }
  }, [vatNumber]);

  const getCompanyData = async() => {
    setLoading(true);

    const vatNumFormated = Number(vatNumber.join().replaceAll(",", ""));

    const res = await fetch(apiUrl+"/cegadat/search/vat/"+vatNumFormated, {
      method: "GET"
    });

    const data = await res.json();

    if(!data.success) {
      setLoading(false);
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    if(data.data.length !== 0) {
      setCompanyData(data.data);
    }

    setLoading(false);
  };

  const handleChange = (index, value) => {
    // Csak számokat fogadjon el
    if (!/^\d*$/.test(value)) return;

    const newVatNumber = [...vatNumber];
    newVatNumber[index] = value;
    setVatNumber(newVatNumber);

    // Automatikus léptetés a következő mezőre, ha van érték
    if (value && index < 7) {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && vatNumber[index] === '' && index > 0) {
      // Visszalépés, ha az aktuális mező üres
      inputRefs.current[index - 1].focus();
    }
  };

  return (
    <Grow in timeout={500}>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', padding: 1, boxSizing: 'border-box', gap: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', gap: 1.5 }}>
          <Box display={{ display: 'flex', gap: 8 }}>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#FFF', padding: 0.8, borderRadius: 1, backgroundColor: '#08979C' }}>
              1. lépés
            </Typography>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#FFF', padding: 0.8, borderRadius: 1, backgroundColor: '#8c8c8c', opacity: 0.2 }}>
              2. lépés
            </Typography>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#FFF', padding: 0.8, borderRadius: 1, backgroundColor: '#8c8c8c', opacity: 0.2 }}>
              3. lépés
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end', width: '100%' }}>
            <Typography
              onClick={onOldReg} // Navigálás az OldReg-re
              sx={{ fontFamily: 'Roboto', fontSize: '12px', color: '#8c8c8c', cursor: 'pointer', '&:hover': { color: '#08979C' } }}
            >
              régi regisztrációs felület
            </Typography>
          </Box>

          <Box>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500', color: '#08979C' }}>Szervezet kiválasztása</Typography>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c' }}>
              Vállassza ki a kezelni kívánt szervezetét. írja be az adószámot majd kattintson a szervezetre
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1, maxWidth: '400px', marginTop: 2 }}>
          <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8c8c8c' }}> Adószám első 8 karaktere:</Typography>
          <Box sx={{ display: 'flex', width: '100%', gap: 1 }}>
            {vatNumber.map((digit, index) => (
              <TextField
                key={index}
                type="tel"
                inputProps={{ maxLength: 1, style: { textAlign: 'center', color: '#08979C', fontWeight: '600' } }}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
                inputRef={(el) => (inputRefs.current[index] = el)}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: digit ? '1.5px solid #08979C' : '0.5px solid #8c8c8c',
                    },
                    '&:hover fieldset': {
                      borderColor: digit ? '#08979C' : '#8c8c8c',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#08979C',
                    },
                  },
                  width: '100%',
                  maxWidth: '50px',
                  textAlign: 'center',
                }}
              />
            ))}
          </Box>
        </Box>

        {companyData.map(item => {
          return (
            <Company
              key={item.id}
              companyData={item}
              onNext={() => onNext(item)}
            />
          );
        })}
      </Box>
    </Grow>
  );
};

export default NewOrgVAT;