import { Box, Typography, IconButton } from '@mui/material';

const OpGroupElement = ({ icon, name, color, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 1,
        borderRadius: 1,
        cursor: 'pointer',
        transition: 'transform 0.3s ease, background-color 0.3s ease',
        '&:hover': {
          backgroundColor: '#f5f5f5',
          transform: 'scale(1.03)',
          boxShadow: 1,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: 1,
        }}
      >
        <IconButton sx={{ color }}>{icon}</IconButton>
        <Typography sx={{ fontWeight: '300', fontSize: '14px', color: '#8c8c8c' }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default OpGroupElement;