import { Typography, Box, Grow, Button } from '@mui/material';

const Company = ({ companyData, onNext } ) => {
  return (
    <Grow in timeout={1000}>
      <Box 
          onClick={onNext}
          sx={{ 
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%', 
            padding: 1.5,
            borderRadius: 1.5,
            maxWidth: '400px',
            cursor: 'pointer',
            opacity: '0.5',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            boxSizing: 'border-box',
            '&:hover': {
                  transform: 'scale(1.02)',
                  border:'none',
                  opacity: 1,
                  backgroundColor: '#f5f5f5',
                  boxShadow: 1,
                }
            }}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#08979C' }}>
                  {companyData.shortName}
                </Typography>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '500', color: '#8c8c8c' }}>
                  {companyData.vatNumber.slice(0, 8)+"-"+companyData.vatNumber[8]+"-"+companyData.vatNumber.slice(9, 11)}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Button size='small' onClick={onNext} sx={{ backgroundColor: '#08979C', color: '#FFF', '&:hover': { backgroundColor: '#68C9CC'} }} >Kiválaszt</Button>
              </Box>
        </Box>
    </Grow>
    
  )
}

export default Company
