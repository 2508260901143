import React from 'react';
import { Button, Box, Typography, Grow } from '@mui/material';
import RegWelcomImg from '../../../img/RegWelcome.png'

const RegWelcome = ({ onNext }) => {
  return (
    <Grow in timeout={1000}>
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100vh', gap: 1 }}>
        <Box component="img" 
                src={RegWelcomImg} 
                alt="Üdvözlő kép"
                sx={{
                width: '100%',
                maxWidth: '350px',
                height: 'auto'
                }} 
            />
        <Box sx={{ display: 'flex',flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: 1, padding: 2, boxSizing: 'border-box'}}>
            <Typography sx={{ fontSize: '24px', fontWeight: 'bold', color: '#08979C' }}>
                Sikeres regisztráció! Üdv a Csapatban!
            </Typography>
            <Typography sx={{ fontSize: '16px', color: '#8c8c8c', textAlign: 'center', maxWidth: '400px' }}>
                Már csak pár lépés választ el hogy vállakozásod még hatékonyabb és eredményesebb legyen.
                Gyere és vágjunk bele közösen a kalandba.
            </Typography>
            <Button size='small' onClick={onNext} sx={{ backgroundColor: '#68C9CC', color: '#FFF', '&:hover': { backgroundColor: '#08979C'} }} >Kezdhetünk</Button>
        </Box>
    </Box>

    </Grow>
  );
};

export default RegWelcome;