import { Grid2, Grow } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import OpList from '../tobaccoHome/content/opList/OpList'
import ShopEvents from './content/shopEvents/ShopEvents'

const TobaccoShopIn = () => {
  return (
    <Box sx={{ flexGrow: 1, padding: 2, mt: 8 }}>
    <Grid2 container spacing={1}>
      <Grow in timeout={800}>
        <Grid2 size={{ xs: 12, md: 12 }}>
          <ShopEvents></ShopEvents>
        </Grid2>
      </Grow>

      <Grow in timeout={800}>
        <Grid2 size={{ xs: 12, md: 12}}>
            <OpList />
        </Grid2>
      </Grow>

      <Grow in timeout={800}>
        <Grid2 size={{ xs: 12, md: 4 }}>
            <OpList />
        </Grid2>
      </Grow>

      <Grow in timeout={1200}>
        <Grid2 size={{ xs:12, md:4}}>
          <OpList />
        </Grid2>
      </Grow>

      <Grow in timeout={1200}>
        <Grid2 size={{ xs:12, md:4}}>
          <OpList />
        </Grid2>
      </Grow>


    </Grid2>
  </Box>
  )
}

export default TobaccoShopIn
