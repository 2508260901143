import { useState } from 'react';

import { Box } from '@mui/material';

import PageHeaderUserDetailsBody from '../../components/PageHeaderUserDetailsBody';
import BaseLogo from '../../components/BaseLogo';
import RegWelcome from './content/RegWelcome';
import NewOrgVAT from './content/NewOrgVAT';
import PackageList from './content/PackageList';
import RegSummarize from './content/RegSummarize';

const NewOrganization = () => {
  const [content, setContent] = useState('welcome');

  const [selectedPackage, setSelectedPackage] = useState("");

  const [companyData, setCompanyData] = useState({
    companyName: "",
    taxNumber: "",
    headquarters: "",
    bankAccount: ""
  });

  const renderContent = () => {
    if(content === 'welcome') {
      return (
        <RegWelcome
          onNext={() => setContent('VAT')}
        />
      );
    }
    if(content === 'VAT') {
      return (
        <NewOrgVAT
          onNext={(formData) => {
            setCompanyData({
              companyName: formData.shortName,
              taxNumber: formData.vatNumber.slice(0, 8)+"-"+formData.vatNumber[8]+"-"+formData.vatNumber.slice(9, 11),
              headquarters: "",
              bankAccount: ""
            });
            setContent('PackageList');
          }}
          onOldReg={() => setContent('OldReg')}
        />
      );
    }

    if(content === 'PackageList') return (
      <PackageList
        onBack={() => setContent('VAT')}
        onSelectPackage={(pkg) => {
          setSelectedPackage(pkg); // Ez biztosítja, hogy a csomag neve mentésre kerüljön
          setContent('Summarize');
        }}
      />
    );

    if(content === 'Summarize') return (
      <RegSummarize
        companyData={companyData}
        selectedPackage={selectedPackage}
        onBack={() => setContent('PackageList')}
      />
    );

    return <div>Hiba történt a tartalom betöltésekor.</div>;
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', gap: 2 }}>
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', boxSizing: 'border-box' }}>
        <BaseLogo />
        <Box sx={{ paddingRight: 2 }}>
          <PageHeaderUserDetailsBody />
        </Box>
      </Box>

      {/* Dinamikus tartalom megjelenítése */}
      <Box sx={{ width: '100%', maxWidth: '800px', alignItems: 'center', justifyContent: 'center' }}>
        {renderContent()}
      </Box>
    </Box>
  );
};

export default NewOrganization;