import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { DataContext } from "../../../context/DataContext";

import {
  Box,
  Button,
  TextField,
  Typography,
  Snackbar,
  Alert,
  Modal,
  IconButton,
  Slide,
  Grow
} from '@mui/material';

import InputAdornment from '@mui/material/InputAdornment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const formatNumber = (number) => {
  if (!number) return '';
  const parsed = number.toString().replace(/\D/g, '');
  return new Intl.NumberFormat('hu-HU').format(parsed);
};

const ConfirmationModal = ({ open, handleClose, handleConfirm }) => (
  <Modal
    open={open}
    onClose={handleClose}
    closeAfterTransition
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Slide direction="up" in={open} mountOnEnter unmountOnExit>
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          width: '90%',
          maxWidth: 400,
          textAlign: 'center',
        }}
      >
        <Typography variant="h6" sx={{ mb: 2 }}>
          Biztosan véglegesíted a befizetést?
        </Typography>
        <Button
          variant="contained"
          onClick={handleConfirm}
          sx={{
            bgcolor: '#08979C',
            color: '#fff',
            fontWeight: '600',
            '&:hover': { bgcolor: '#68C9CC' },
            mr: 1,
          }}
        >
          Igen
        </Button>
        <Button
          variant="outlined"
          onClick={handleClose}
          sx={{
            borderColor: '#08979C',
            color: '#08979C',
            fontWeight: '600',
            '&:hover': { bgcolor: '#f0f0f0' },
          }}
        >
          Nem
        </Button>
      </Box>
    </Slide>
  </Modal>
);

const CashIn = () => {
  const {user, setMsg} = useContext(DataContext);

  const [formData, setFormData] = useState({
    amount: '',
    note: '',
  });
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const navigate = useNavigate();

  const apiUrl = process.env.REACT_APP_API_URL;

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => setModalOpen(false);

  const handleSubmit = async() => {
    const res = await fetch(apiUrl+"/cashregister/add", {
      method: "POST",
      headers: {
          "Content-Type": "application/json"
      },
      body: JSON.stringify({
          user: user.id,
          store: user.store,
          company: user.company.id,
          operation: 10,
          sum: formData.amount,
          comment: formData.note
      })
    });

    const data = await res.json();

    if(!data.success){
      return setMsg({
        color: "error",
        message: data.message
      });
    }

    // Visszaállítjuk az űrlap mezőket
    setFormData({
      amount: '',
      note: '',
    });

    setSnackbarOpen(true); // Értesítés megjelenítése
    setModalOpen(false); // Modal bezárása
  };

  return (
    <Grow in timeout={800}>
    <Box sx={{ maxWidth: '400px', margin: 'auto', mt: 12, padding: 2, boxSizing: 'border-box' }}>
      {/* Fejléc */}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 3,
        }}
      >
        <IconButton
          onClick={() => navigate('/tobacco')}
          sx={{
            color: '#08979C',
            '&:hover': { bgcolor: '#f0f0f0' },
          }}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography
          variant="h6"
          sx={{
            color: '#08979C',
            fontWeight: '600',
          }}
        >
          Pénz be művelet
        </Typography>
      </Box>

      {/* Tartalom */}
      <TextField
        label="Befizetett összeg"
        fullWidth
        value={formData.amount ? formatNumber(formData.amount) : ''}
        onChange={(e) => {
          const rawValue = e.target.value.replace(/\D/g, '');
          if (rawValue.length <= 7) {
            setFormData({ ...formData, amount: rawValue });
          }
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Typography sx={{ fontWeight: '600', color: '#08979C' }}>Ft</Typography>
            </InputAdornment>
          ),
        }}
        inputProps={{
          inputMode: 'numeric',
          maxLength: 7,
        }}
        type="text"
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#d9d9d9',
            },
            '&:hover fieldset': {
              borderColor: '#08979C',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#08979C',
            },
            '& input': {
              color: '#08979C',
              fontWeight: '600',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#8c8c8c',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#08979C',
          },
        }}
      />
      <TextField
        label="Megjegyzés"
        fullWidth
        multiline
        value={formData.note}
        onChange={(e) => setFormData({ ...formData, note: e.target.value })}
        sx={{
          mb: 2,
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#d9d9d9',
            },
            '&:hover fieldset': {
              borderColor: '#08979C',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#08979C',
            },
            '& textarea': {
              color: '#8c8c8c',
              fontWeight: '300',
              fontSize: '16px',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#8c8c8c',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#08979C',
          },
        }}
      />
      <Button
        variant="contained"
        fullWidth
        onClick={handleOpenModal}
        disabled={!formData.amount || !formData.note}
        sx={{
          bgcolor: '#08979C',
          fontWeight: '600',
          color: '#fff',
          '&:hover': { bgcolor: '#68C9CC' },
        }}
      >
        Véglegesítés
      </Button>

      <ConfirmationModal
        open={modalOpen}
        handleClose={handleCloseModal}
        handleConfirm={handleSubmit}
      />

        <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        >
            <Slide direction="up" in={setSnackbarOpen} timeout={800} mountOnEnter unmountOnExit>
            <Alert onClose={() => setSnackbarOpen(false)} severity="success" 
            sx={{ 
                width: '100%', 
                backgroundColor: '#08979c', 
                color: '#fff',
                '& .MuiAlert-icon': {
                color: '#fff',} // Az ikon színének beállítása
                }}>
            Befizetés sikeresen mentve!
            </Alert>
            </Slide>
      </Snackbar>
    </Box>
    </Grow>
  );
};

export default CashIn;