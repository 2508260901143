import { useState } from 'react';

import {
  Box,
  Modal,
  Typography,
  Avatar,
  Button,
  Slide,
  TextField,
  IconButton,
  Grid2,
} from '@mui/material';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ChangeFileFinalizeModal = ({ open, users, onClose, onVerify }) => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [pw, setPw] = useState("");

  const [viewPassword, setViewPassword] = useState(false);

  const handleUserSelect = (user) => {
    setSelectedUser(user);
    setViewPassword(true);
  };

  const handleBack = () => {
    setSelectedUser(null);
    setViewPassword(false);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center', // Középre igazítás vertikálisan
        justifyContent: 'center', // Középre igazítás horizontálisan
        padding: 2,
      }}
    >
      <Slide direction="up" in={open} timeout={800}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            width: '100%',
            maxWidth: 400,
            textAlign: 'center',
          }}
        >
          {!viewPassword ? (
            <>
              <Typography sx={{ mb: 2, color: '#8C8C8C' }}>
                Válassz felhasználót
              </Typography>
              <Grid2 container spacing={1}>
                {users.map(user => (
                  <Grid2 size={12} key={user.id}>
                    <Box
                      onClick={() => handleUserSelect(user)}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        p: 2,
                        border: '1px solid #d9d9d9',
                        borderRadius: 2,
                        cursor: 'pointer',
                        '&:hover': { borderColor: '#08979C' },
                      }}
                    >
                      <Avatar sx={{ bgcolor: '#08979C' }}>{user.last_name[0]}</Avatar>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                        <Typography sx={{ fontWeight: '600' }}>{user.last_name+" "+user.first_name}</Typography>
                        <Typography sx={{ fontSize: '12px', color: '#8c8c8c' }}>
                          {user?.role}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid2>
                ))}
              </Grid2>
            </>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <IconButton onClick={handleBack} sx={{ mr: 1, color: '#8C8C8C' }}>
                  <ArrowBackIcon />
                </IconButton>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '300', color: '#8C8C8C' }}>
                  Vissza a felhasználókhoz
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'start'}}>
                <Typography sx={{ mb: 2, color: '#8C8C8C' }}>
                  {selectedUser && "Kiválasztott felhasználó: "+selectedUser.last_name+" "+selectedUser.first_name}
                </Typography>                
              </Box>

              <TextField
                type="password"
                label="Jelszó"
                value={pw}
                onChange={(e) => setPw(e.target.value)}
                fullWidth
                sx={{
                  mb: 3,
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: '#d9d9d9',
                    },
                    '&:hover fieldset': {
                      borderColor: '#08979C',
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: '#08979C',
                    },
                  },
                }}
              />
              <Button
                variant="contained"
                fullWidth
                onClick={() => onVerify(selectedUser, pw)}
                sx={{
                  bgcolor: '#08979C',
                  color: '#fff',
                  '&:hover': { bgcolor: '#68C9CC' },
                }}
              >
                Véglegesítés
              </Button>
            </>
          )}
        </Box>
      </Slide>
    </Modal>
  );
};

export default ChangeFileFinalizeModal;