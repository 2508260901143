import { Avatar, Grow, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import ProfilGirl from '../../../../../img/nagy_kati.jpeg';

const EventElement = () => {
  return (
    <Grow in timeout={1000}>
        <Box sx={{ display: 'flex', padding: 1, gap: 1, alignItems: 'center' }}>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '10px', fontWeight: '300', color: '#8c8c8c'}}>4:59:22</Typography>
            <Avatar
                src={ProfilGirl}// Ide helyezd a hölgy profilképének URL-jét
                alt="Profilkép"
                sx={{ 
                    width: 32, 
                    height: 32,
                    objectFit: 'cover', // A kép arányainak megtartása
                    objectPosition: 'center', // A kép pozíciójának középre igazítása
                }}
            />
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', color: '#8c8c8c'}}>Napnyitás</Typography>
        </Box>
    </Grow>
  )
}

export default EventElement
