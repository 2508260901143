import { Grow, Box, Typography, Button } from '@mui/material';
import React from 'react';
import PackageElement from './PackageElement';

const PackageList = ({ onBack, onSelectPackage }) => {
  // Csomagok adatai
  const packages = [
    {
      title: 'FREE számla csomag',
      description: 'Az Ingyenes Számlacsomag lehetővé teszi a vállalkozások számára, hogy alapvető számlázási funkciókat érjenek el a rendszerünkön belül, költségek nélkül.',
      features: [
        'Valósidejű NAV kapcsolat',
        'Korlátlan számlázás',
        'Díjmentes ügyfélszolgálat',
        'Online szerződéskötés',
        'Banki integráció',
        'Adatmentés és helyreállítás',
      ],
      value: 3, // A továbbított érték
    },
    {
      title: 'TOBACCO számla csomag',
      description: 'A Tobacco csomag különösen hasznos a dohányboltok számára, teljes körű számlázási és jelentési szolgáltatásokkal.',
      features: [
        'NAV online adatkapcsolat',
        'Speciális adatszolgáltatások',
        'Készletkezelés támogatása',
        'Valósidejű riportok',
        'Korlátlan számlázás',
        'Ügyfélszolgálati támogatás',
      ],
      value: 1, // A továbbított érték
    },
    {
      title: 'APARTMAN számla csomag',
      description: 'A Rent csomag ideális bérbeadóknak, teljes körű számlázási lehetőségekkel és automatikus számlaemlékeztetőkkel.',
      features: [
        'Automatikus számlázás',
        'Bérleti riportok',
        'NAV kapcsolat',
        'Automatikus emlékeztetők',
        'Adatmentés',
        'Ügyfélszolgálat',
      ],
      value: 2, // A továbbított érték
    },
  ];

  return (
    <Grow in timeout={500}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          alignItems: 'center',
          padding: 1,
          boxSizing: 'border-box',
          gap: 4,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', gap: 1.5 }}>
          <Box display={{ display: 'flex', gap: 8 }}>
            <Typography
              onClick={onBack}
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: '500',
                color: '#FFF',
                padding: 0.8,
                borderRadius: 1,
                backgroundColor: '#08979C',
                opacity: 0.2,
                cursor: 'pointer',
                '&:hover': { opacity: 1 },
              }}
            >
              1. lépés
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: '500',
                color: '#FFF',
                padding: 0.8,
                borderRadius: 1,
                backgroundColor: '#08979C',
              }}
            >
              2. lépés
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontWeight: '500',
                color: '#FFF',
                padding: 0.8,
                borderRadius: 1,
                backgroundColor: '#8c8c8c',
                opacity: 0.2,
              }}
            >
              3. lépés
            </Typography>
          </Box>
          <Box>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '16px', fontWeight: '500', color: '#08979C' }}>
              Csomag kiválasztás
            </Typography>
            <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c' }}>
              Vállasszon a csomagjaink közül
            </Typography>
          </Box>
        </Box>

        {/* Csomag elemek dinamikus megjelenítése */}
        {packages.map((pkg, index) => (
          <PackageElement
            key={index}
            title={pkg.title}
            description={pkg.description}
            features={pkg.features}
            onSelect={() => onSelectPackage(pkg.value)} // Javított értékátadás
          />
        ))}

        <Box>
          <Button
            size="small"
            onClick={onBack}
            sx={{ backgroundColor: '#68C9CC', color: '#FFF', '&:hover': { backgroundColor: '#08979C' } }}
          >
            Vissza szervezet kiválasztáshoz
          </Button>
        </Box>
      </Box>
    </Grow>
  );
};

export default PackageList;