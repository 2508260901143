import React, { useState } from 'react';
import { Box } from '@mui/material';

// Iconok és képek
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import KasszaIcon from '@mui/icons-material/PointOfSale'; 
import MuszakvaltasIcon from '@mui/icons-material/PeopleOutline'; 
import NapzarasIcon from '@mui/icons-material/NightsStayOutlined'; 
import NapnyitasIcon from '@mui/icons-material/LightModeOutlined'; 
import EladoterIcon from '@mui/icons-material/LocalGroceryStoreOutlined';
import SzamlaIcon from '@mui/icons-material/DescriptionOutlined'; 
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import LiquorOutlinedIcon from '@mui/icons-material/LiquorOutlined';
import AssuredWorkloadOutlinedIcon from '@mui/icons-material/AssuredWorkloadOutlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';

// Komponensek
import SubMenuList from '../components/SubMenuList';
import Eladoter from '../pages/Eladoter';
import NavHeader from './content/NavHeader';
import FloatMenuButton from './content/FloatMenuButton';
import MenuModal from './content/MenuModal';
import Settings from '../pages/Settings';

const subMenuItems = [
    { categoryID: 1, icon: <GridViewOutlinedIcon sx={{ fontSize: 32 }} />, text: "Almenüpont 1", content: <div>Almenüpont 1 Tartalom</div> },
    { categoryID: 1, icon: <SettingsOutlinedIcon sx={{ fontSize: 32 }} />, text: "Beállítások", content: <Settings /> },
    { categoryID: 3, icon: <InventoryOutlinedIcon sx={{ fontSize: 32 }} />, text: "Eladótér", content: <Eladoter /> },
    { categoryID: 2, icon: <NapnyitasIcon sx={{ fontSize: 32 }} />, text: "Napnyitás", content: <Eladoter /> },    
    { categoryID: 2, icon: <MuszakvaltasIcon sx={{ fontSize: 32 }} />, text: "Műszakváltás", content: <Eladoter /> },
    { categoryID: 2, icon: <NapzarasIcon sx={{ fontSize: 32 }} />, text: "Napzárás", content: <Eladoter /> },
    { categoryID: 2, icon: <EladoterIcon sx={{ fontSize: 32 }} />, text: "Forgalom", content: <Eladoter /> },
];

const Dashboard = ({children}) => {
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedMenu, setSelectedMenu] = useState(null);
    const [isSmallScreen] = useState(window.innerWidth < 600);
    const [slideDirection, setSlideDirection] = useState('down');

    const handleOpen = () => {
        setSlideDirection('up');
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedMenu(null);
        setSearchTerm('');
        setSlideDirection('up');
        setTimeout(() => setOpen(false), 500);
    };

    const handleMenuClick = (menu) => {
        setSelectedMenu(menu);
    };

    const handleBack = () => {
        setSelectedMenu(null);
        setSearchTerm('');
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
    };

    const menuItems = [
        { icon: <GridViewOutlinedIcon sx={{ fontSize: 32 }} />, text: "Összes művelet", content: <SubMenuList categoryID={1} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <KasszaIcon sx={{ fontSize: 32 }} />, text: "Kassza műveletek", content: <SubMenuList categoryID={2} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <SzamlaIcon sx={{ fontSize: 32 }} />, text: "Számla műveletek", content: <SubMenuList categoryID={3} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <InventoryOutlinedIcon sx={{ fontSize: 32 }} />, text: "Bevételezés", content: <SubMenuList categoryID={4} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <LiquorOutlinedIcon sx={{ fontSize: 32 }} />, text: "Termékek", content: <SubMenuList categoryID={5} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <AssuredWorkloadOutlinedIcon sx={{ fontSize: 32 }} />, text: "Utalások", content: <SubMenuList categoryID={6} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <SavingsOutlinedIcon sx={{ fontSize: 32 }} />, text: "Széf és megtakarítás", content: <SubMenuList categoryID={7} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <QueryStatsOutlinedIcon sx={{ fontSize: 32 }} />, text: "Riportok és kimutatások", content: <SubMenuList categoryID={8} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <SettingsOutlinedIcon sx={{ fontSize: 32 }} />, text: "Beállítások", content: <SubMenuList categoryID={9} subMenuItems={subMenuItems} onClose={handleClose} /> },
        { icon: <GroupOutlinedIcon sx={{ fontSize: 32 }} />, text: "Felhasználók kezelése", content: <SubMenuList categoryID={10} subMenuItems={subMenuItems} onClose={handleClose} /> },
    ];

    const filteredMenuItems = isSmallScreen
        ? menuItems.filter((item) => item.text.toLowerCase().includes(searchTerm))
        : menuItems;

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100vh' }}>
            <NavHeader onMenuOpen={handleOpen} />
            <FloatMenuButton handleOpen={handleOpen} />
            <MenuModal
                open={open}
                onClose={handleClose}
                slideDirection={slideDirection}
                isSmallScreen={isSmallScreen}
                selectedMenu={selectedMenu}
                onBack={handleBack}
                onSearchChange={handleSearchChange}
                searchTerm={searchTerm}
                filteredMenuItems={filteredMenuItems}
                handleMenuClick={handleMenuClick}
            />
            {children}
        </Box>
    );
};

export default Dashboard;