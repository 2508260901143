import React from 'react';
import { Grid2 } from '@mui/material';
import SubMenuElements from './SubMenuElements';

const SubMenuList = ({ categoryID, subMenuItems, onClose, setSelectedContent }) => {
    const filteredSubMenuItems = subMenuItems.filter(item => item.categoryID === categoryID);

    return (
        <Grid2 container spacing={2}>
            {filteredSubMenuItems.map((item, index) => (
                <Grid2 item size={{ xs: 6, sm: 4, md: 4 }} key={index}>
                    <SubMenuElements 
                        icon={item.icon} 
                        text={item.text}
                        onClick={() => {
                            onClose(); // Modal bezárása
                            if (item.content) {
                                setSelectedContent({
                                    content: item.content,
                                    icon: item.icon,
                                    text: item.text,
                                }); // Állapot beállítása az összes adattal
                            }
                        }}
                    />
                </Grid2>
            ))}
        </Grid2>
    );
};

export default SubMenuList;