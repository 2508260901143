import React, { useState, useEffect } from 'react';
import { Box, Fade, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';

const Settings = ({ onClose, icon, text }) => {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true); // Beállítjuk, hogy az animáció elinduljon a komponens betöltésekor
    return () => setFadeIn(false); // Amikor a komponens eltűnik, visszaállítjuk az állapotot
  }, []);
  return (
    <Fade in={fadeIn} timeout={1200}>
    <Box sx={{ width: '100%', display: 'flex', gap: 0.5, padding: 1, alignItems: 'flex-end' }}>
      <Box 
        component={Link} 
        onClick={onClose}
        sx={{ 
          display: 'flex',
          alignItems: 'flex-end',
          textDecoration: 'none', 
          color: '#8c8c8c',
          '&:hover': { opacity: 0.5 },
          cursor: 'pointer'
        }}
      >
        <HomeOutlinedIcon sx={{ fontSize: 2, color: '#8c8c8c' }} />
        <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c', marginLeft: 0.5 }}>
          Vissza a főoldalra
        </Typography>
      </Box>
      
      <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', color: '#8c8c8c' }}> / </Typography>
      
      <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '600', color: '#000' }}>{text}</Typography>
    </Box>
    </Fade>
  );
};

export default Settings;