import { createRef } from 'react';

import { getCardSum, getTrafficCashIn, getTrafficCashOut, getTrafficSum } from '../../../../services/TrafficCalc';

import {
  Box,
  Typography,
  TextField,
  Grid2,
  Grow,
} from '@mui/material';

import CloseFileDataInputBase from './CloseFileDataInputBase';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';

const CloseFileCassa = ({ traffic, round, cancel, setCancel, comment, setComment, amounts, setAmounts, total, setTotal, currencies }) => {
  const inputRefs = Array(currencies.length)
    .fill(null)
    .map(() => createRef());

  const handleInputChange = (index, value) => {
    const newAmounts = [...amounts];
    newAmounts[index] = Number(value) || 0;
    setAmounts(newAmounts);

    const newTotal = newAmounts.reduce(
      (sum, amount, i) => sum + amount * currencies[i].value,
      0
    );
    setTotal(newTotal);
  };

  const handleKeyPress = (index, event) => {
    if (event.key === 'Enter') {
      if (index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    } else if (event.key === 'Backspace') {
      if (amounts[index] === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      }
    }
  };

  return (
    <Grow in timeout={800}>
      <Box
        sx={{
          flexGrow: 1,
          padding: 1,
          alignItems: 'center',
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        {/* Pénzegységek mezői */}
        <Grid2 container spacing={1}>
          <Grid2 size={{ xs: 12, md: 8 }}>
          <Box sx={{ display: 'flex', padding: 1 }}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', padding: 0.8, backgroundColor: '#08979C', color: '#FFF', borderRadius: 1}}>Címletjegyzék</Typography>  
            </Box>
            <Grid2 size={12} sx={{ padding: 1 }}>
              <Grid2 container spacing={2}>
                {currencies.map((currency, index) => (
                  <Grid2
                    size={3}
                    key={index}
                    sx={{
                      opacity: amounts[index] === 0 ? 0.3 : 1,
                      transition: 'opacity 0.3s ease',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: 0.5,
                      }}
                    >
                      <Typography sx={{ fontWeight: 'bold', fontSize: '12px' }}>
                        {currency.name}
                      </Typography>
                      <TextField
                        size="small"
                        value={amounts[index]}
                        onChange={(e) => handleInputChange(index, e.target.value)}
                        onKeyDown={(e) => handleKeyPress(index, e)}
                        onFocus={(e) => e.target.select()}
                        variant="outlined"
                        inputRef={inputRefs[index]}
                        inputProps={{
                          min: 0,
                          inputMode: 'numeric',
                        }}
                        sx={{
                          width: '70px',
                          color: '#08979C',
                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#d9d9d9',
                            },
                            '&:hover fieldset': {
                              borderColor: '#08979C',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#08979C',
                            },
                          },
                          '& .MuiInputBase-input': {
                            textAlign: 'center',
                            color: '#08979C',
                            fontSize: '16px',
                            fontWeight: '500',
                          },
                        }}
                      />
                      <Typography sx={{ fontSize: '10px', color: '#8c8c8c' }}>
                        {amounts[index] * currency.value} Ft
                      </Typography>
                    </Box>
                  </Grid2>
                ))}
              </Grid2>
            </Grid2>
          </Grid2>

          {/* Összegző rész */}
          <Grid2 size={{ xs: 12, md: 4 }}>
            <Grid2 size={12} sx={{ height: '100%' }}>
                <Box sx={{ display: 'flex', padding: 1 }}>
                    <Typography sx={{ fontFamily: 'Roboto', fontSize: '14px', fontWeight: '300', padding: 0.8, backgroundColor: '#08979C', color: '#FFF', borderRadius: 1}}>Összegzés</Typography>  
                </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: 2,
                  height: '100%',
                }}
              >
                <CloseFileDataInputBase
                  label="Számolt kassza tartalom"
                  value={total}
                  icon={null}
                />

                <CloseFileDataInputBase
                  label="Gép szerinti fiók tartalom"
                  value={(getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)-getCardSum(traffic)}
                  icon={null}
                />

                <Box sx={{ mt: 2 }}>
                  <TextField
                    label="Kézi sztornó"
                    fullWidth
                    value={cancel}
                    onChange={(e) => setCancel(e.target.value)}
                    InputProps={{
                      endAdornment: (
                        <DriveFileRenameOutlineOutlinedIcon
                          sx={{ color: '#8c8c8c' }} // Fix szín
                        />
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: '#d9d9d9',
                        },
                        '&:hover fieldset': {
                          borderColor: '#08979C',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#08979C',
                        },
                        '& input': {
                          color: '#08979C',
                          fontWeight: '600',
                        },
                      },
                      '& .MuiInputLabel-root': {
                        color: '#8c8c8c',
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#08979C',
                      },
                    }}
                  />
                </Box>

                <CloseFileDataInputBase
                  label="Eltérés"
                  value={total-((getTrafficSum(traffic)+getTrafficCashIn(traffic))-getTrafficCashOut(traffic)+Number(round)-getCardSum(traffic))}
                  icon={null}
                />
              </Box>
            </Grid2>
          </Grid2>

          {/* Megjegyzés */}
          <Grid2 size={12} sx={{ mt: 2 }}>
            <TextField
              label="Megjegyzés"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              fullWidth
              multiline
              rows={2}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: '#d9d9d9',
                  },
                  '&:hover fieldset': {
                    borderColor: '#08979C',
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: '#08979C',
                  },
                  '& textarea': {
                    color: '#8c8c8c',
                    fontWeight: '300',
                    fontSize: '16px',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#8c8c8c',
                },
                '& .MuiInputLabel-root.Mui-focused': {
                  color: '#08979C',
                },
              }}
            />
          </Grid2>
        </Grid2>
      </Box>
    </Grow>
  );
};

export default CloseFileCassa;