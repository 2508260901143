import React, { useState } from 'react';
import { Box } from '@mui/system';
import { InputAdornment, TextField, IconButton } from '@mui/material';

const CloseFileDataInputBase = ({ label, value, icon }) => {
  const [isActive, setIsActive] = useState(false); // Kezdeti állapot

  const handleIconClick = () => {
    setIsActive((prev) => !prev); // Állapot váltás
  };

  return (
    <Box sx={{ mt: 2 }}>
      <TextField
        label={label}
        fullWidth
        value={value} // Fix érték
        InputProps={{
          readOnly: true, // Csak olvashatóvá teszi a mezőt
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleIconClick}
                sx={{
                  color: isActive ? '#08979C' : '#8c8c8c', // Szín állapottól függően
                  '&:hover': { color: '#08979C' }, // Hover szín
                }}
              >
                {icon}
              </IconButton>
            </InputAdornment>
          ),
        }}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#d9d9d9',
            },
            '&:hover fieldset': {
              borderColor: '#08979C',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#08979C',
            },
            '& input': {
              color: '#08979C',
              fontWeight: '600',
            },
          },
          '& .MuiInputLabel-root': {
            color: '#8c8c8c',
          },
          '& .MuiInputLabel-root.Mui-focused': {
            color: '#08979C',
          },
        }}
      />
    </Box>
  );
};

export default CloseFileDataInputBase;