import { useState } from 'react';

import { SketchPicker } from 'react-color';

import { Box, TextField, Button, Typography, Avatar, Grid, IconButton, Grow, Modal } from '@mui/material';

import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';

const AddNewShop = ({ onAddShop, onCancel }) => {
  const [shopData, setShopData] = useState({
    name: '',
    address: '',
    color: '#68C9CC',
    initials: ''
  });

  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const handleChange = (field) => (event) => {
    setShopData((prev) => ({ ...prev, [field]: event.target.value }));
  };

  const handleColorChange = (color) => {
    setShopData((prev) => ({ ...prev, color: color.hex }));
  };

  const handleSubmit = () => {
    if (shopData.name && shopData.address && shopData.initials) {
      onAddShop(shopData); // Adatok továbbítása a szülő komponenshez
      setShopData({
        name: '',
        address: '',
        initials: '',
        color: '#68C9CC'
      }); // Alaphelyzetbe állítás
    } else {
      alert('Kérlek, töltsd ki az összes mezőt!');
    }
  };

  return (
    <Grow in timeout={800}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 0.5,
        }}
      >
        {/* Fejléc */}
        <Box sx={{ display: 'flex', backgroundColor: '#08979C', padding: 1, borderRadius: 1 }}>
          <Typography sx={{ color: '#fff', fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300' }} gutterBottom>
            Új eladótér létrehozása
          </Typography>
        </Box>

        {/* Előnézet */}
        <Box sx={{ display: 'flex', padding: 1, alignItems: 'center' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 2,
              padding: 1,
              border: '1px dashed #8c8c8c',
              borderRadius: 1,
              width: '100%',
            }}
          >
            <Box sx={{ display: 'flex', gap: 1 }}>
              {/* Avatar */}
              <Avatar
                sx={{
                  bgcolor: shopData.color,
                  width: 32,
                  height: 32,
                  fontSize: '10px',
                }}
              >
                {shopData.initials || 'N/A'}
              </Avatar>
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Dinamikusan frissített megnevezés */}
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '12px',
                    fontWeight: '500',
                    color: '#8c8c8c',
                  }}
                >
                  {shopData.name || 'Eladótér megnevezése'}
                </Typography>
                {/* Dinamikusan frissített cím */}
                <Typography
                  sx={{
                    fontFamily: 'Roboto',
                    fontSize: '10px',
                    fontWeight: '300',
                    color: '#8c8c8c',
                  }}
                >
                  {shopData.address || 'Eladótér címe'}
                </Typography>
              </Box>
            </Box>
            <IconButton
              variant="contained"
              onClick={() => setColorPickerVisible(true)} // Modal megnyitása
            >
              <FormatColorFillIcon />
            </IconButton>
          </Box>
        </Box>

        {/* Űrlap / Bevitelimezők */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, padding: 1 }}>
          {/* Shop név */}
          <TextField
            size="small"
            label="Eladótér megnevezése"
            variant="outlined"
            value={shopData.name}
            onChange={handleChange('name')}
            required
            sx={{
              marginBottom: 1,
              width: '100%',
              '& .MuiInputBase-root': {
                fontSize: '14px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#08979C',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9',
                },
                '&:hover fieldset': {
                  borderColor: '#08979C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#68C9CC',
                },
              },
            }}
          />

          {/* Shop cím */}
          <TextField
            size="small"
            label="Eladótér címe"
            variant="outlined"
            value={shopData.address}
            onChange={handleChange('address')}
            required
            sx={{
              marginBottom: 1,
              width: '100%',
              '& .MuiInputBase-root': {
                fontSize: '14px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#08979C',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9',
                },
                '&:hover fieldset': {
                  borderColor: '#08979C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#68C9CC',
                },
              },
            }}
          />

          {/* Kezdőbetűk */}
          <TextField
            size="small"
            label="Eladótér kezdőbetűk"
            variant="outlined"
            value={shopData.initials}
            onChange={handleChange('initials')}
            inputProps={{ maxLength: 2 }}
            required
            sx={{
              marginBottom: 1,
              width: '100%',
              '& .MuiInputBase-root': {
                fontSize: '14px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#08979C',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9',
                },
                '&:hover fieldset': {
                  borderColor: '#08979C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#68C9CC',
                },
              },
            }}
          />
        </Box>

        {/* Színválasztó modális */}
        <Modal
          open={colorPickerVisible}
          onClose={() => setColorPickerVisible(false)}
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              padding: 2,
              borderRadius: 1,
              boxShadow: 24,
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <SketchPicker
              color={shopData.color}
              onChangeComplete={handleColorChange}
            />
          </Box>
        </Modal>

        {/* Műveleti gombok */}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
            size='small'
              variant="outlined"
              fullWidth
              onClick={onCancel}
              sx={{ border: '1px solid #68C9CC', color: '#68C9CC', fontSize: '10px' }}
            >
              Mégsem
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              size='small'
              variant="contained"
              fullWidth
              onClick={handleSubmit}
              sx={{ bgcolor: '#08979C', fontSize: '10px', '&:hover': { bgcolor: '#68C9CC' } }}
            >
              Létrehozás
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Grow>
  );
};

export default AddNewShop;