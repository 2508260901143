import { Box, Modal, Typography, Button, Slide } from '@mui/material';

const CloseFileFinalizeModal = ({ open, onClose, onConfirm }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      closeAfterTransition
      sx={{
        display: 'flex',
        alignItems: 'center', // Középre igazítás vertikálisan
        justifyContent: 'center', // Középre igazítás horizontálisan
        padding: 2,
      }}
    >
      <Slide direction="up" in={open} timeout={800}>
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
            borderRadius: 2,
            p: 3,
            width: '100%',
            maxWidth: 400,
            textAlign: 'center',
          }}
        >
          <Typography sx={{ mb: 3, fontSize: '16px', color: '#8C8C8C' }}>
            Biztos lezárod a napot?
          </Typography>
          <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center' }}>
            <Button
              variant="outlined"
              onClick={onClose}
              sx={{
                color: '#08979C',
                borderColor: '#08979C',
                '&:hover': { bgcolor: '#f0f0f0' },
              }}
            >
              Mégsem
            </Button>
            <Button
              variant="contained"
              onClick={onConfirm}
              sx={{
                bgcolor: '#08979C',
                color: '#fff',
                '&:hover': { bgcolor: '#68C9CC' },
              }}
            >
              Igen, lezárom
            </Button>
          </Box>
        </Box>
      </Slide>
    </Modal>
  );
};

export default CloseFileFinalizeModal;