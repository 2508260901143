import React, { useState } from 'react';

//mui elemek
import { Button, Grid2, Modal, IconButton, Drawer, List, ListItem, ListItemButton, ListItemText, Slide, Typography, Box } from '@mui/material';

//componensek
import LoginField from '../components/LoginField';
import RegistrationField from '../components/RegistrationField';
import BaseLogo from '../components/BaseLogo';

//iconok és képek
import LandingPic from '../img/landing.png';
import MenuIcon from '@mui/icons-material/Menu';

const Landing = () => {
  const [openRegistration, setOpenRegistration] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleOpenRegistration = () => setOpenRegistration(true);
  const handleCloseRegistration = () => setOpenRegistration(false);
  const handleOpenLogin = () => setOpenLogin(true);
  const handleCloseLogin = () => setOpenLogin(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <Box id="Landing body" sx={{ display: 'flex', flexDirection: 'column', width: '100vw', height: '100%', maxWidth: '1600px', margin: '0 auto' }}>
      <Grid2 container id="Landing header" sx={{ width: '100%', height: 'auto', padding: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center', boxSizing: 'border-box' }}>
        <Grid2 item size={{ xs: 12, sm: 12, md: 4 }} sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', gap: 0, justifyContent: 'space-between', width: '100%' }}>
            <BaseLogo />            
            <IconButton sx={{ display: { md: 'none' } }} onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
          </Box>
        </Grid2>

        <Grid2 item size={{ xs: 0, sm: 0, md: 4 }} sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', gap: 3 }}>
          <Button color="inherit" sx={{ color: '#8c8c8c', fontFamily: 'Roboto', fontWeight: '300', fontSize: '12px', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', transform: 'scale(1.03)', padding: '0px 8px' } }}>Főoldal</Button>
          <Button color="inherit" sx={{ color: '#8c8c8c', fontFamily: 'Roboto', fontWeight: '300', fontSize: '12px', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', transform: 'scale(1.03)', padding: '0px 8px' } }}>Megoldásaink</Button>
          <Button color="inherit" sx={{ color: '#8c8c8c', fontFamily: 'Roboto', fontWeight: '300', fontSize: '12px', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', transform: 'scale(1.03)', padding: '0px 8px' } }}>Rólunk</Button>
          <Button color="inherit" sx={{ color: '#8c8c8c', fontFamily: 'Roboto', fontWeight: '300', fontSize: '12px', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', transform: 'scale(1.03)', padding: '0px 8px' } }}>Csomagok</Button>
          <Button color="inherit" sx={{ color: '#8c8c8c', fontFamily: 'Roboto', fontWeight: '300', fontSize: '12px', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', transform: 'scale(1.03)', padding: '0px 8px' } }}>Kapcsolat</Button>
        </Grid2>

        <Grid2 item size={{ xs: 12, sm: 12, md: 3 }} sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1, padding: 1 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleOpenRegistration}
            sx={{ mt: 0, border: '0.5px solid #68C9CC', padding: 1, fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', color: '#68C9CC', '&:hover': { backgroundColor: '#68C9CC', color: '#FFF', border: '0.5px solid #048489', transform: 'scale(1.03)' } }}
          >
            Regisztráció
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenLogin}
            sx={{ mt: 0, backgroundColor: '#048489', padding: 1, fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300', '&:hover': { backgroundColor: '#68C9CC', transform: 'scale(1.03)' } }}
          >
            Bejelentkezés
          </Button>
        </Grid2>
      </Grid2>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
          <List>
            {['Főoldal', 'Megoldásaink', 'Rólunk', 'Csomagok', 'Kapcsolat'].map((text) => (
              <ListItem key={text} disablePadding>
                <ListItemButton>
                  <ListItemText primary={text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>

      <Box sx={{ display: 'flex', flexDirection: 'column', padding: 2, maxHeight: 'calc(100vh - 100px)', height: '100%' }}>
        <Grid2 container columnSpacing={2} sx={{ flexGrow: 1, width: '100%', maxHeight: '100%', height: '100%' }}>
            <Grid2 item size={{ xs: 12, sm: 12, md: 8 }} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Grid2 size={{ xs: 11, sm: 11, md: 9 }} sx={{ backgroundColor: '#08979C', borderRadius: '4px', justifyContent: 'flex-end', display: 'flex', height: 'auto' }}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '24px', color: '#FFF', padding: 1 }}>A vállakozásod jövője</Typography>
            </Grid2>
            <Grid2 size={{ xs: 11, sm: 11, md: 8 }} sx={{ backgroundColor: '#FFF', borderRadius: '0px 4px 4px 0px', justifyContent: 'flex-end', display: 'flex', height: 'auto' }}>
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '20px', fontWeight: '300', color: '#08979C', padding: 1 }}>...mert okosan vállalkozni menő</Typography>
            </Grid2>
            <Grid2 item size={12} sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', maxHeight: '50%' }}>
                <img src={LandingPic} alt="Login visual" style={{ width: '80%' }} />
            </Grid2>
            </Grid2>
            <Grid2 item size={{ xs: 12, sm: 12, md: 4, lg: 4 }} sx={{ bgcolor: '#D3E8F0', borderRadius: 2, height: 'auto', maxHeight: '100%' }}>
              <Typography>Valami</Typography>
            </Grid2>
        </Grid2>
        </Box>

      <Modal open={openRegistration} onClose={handleCloseRegistration} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: 1 }}>
        <Slide direction="down" in={openRegistration} mountOnEnter unmountOnExit timeout={400}>
          <Box sx={{ bgcolor: '#FFF', padding: 0, borderRadius: 0, boxShadow: 32 }}>
            <RegistrationField onBackClick={handleCloseRegistration} />
          </Box>
        </Slide>
      </Modal>

      <Modal open={openLogin} onClose={handleCloseLogin} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Slide direction="down" in={openLogin} mountOnEnter unmountOnExit timeout={400}>
          <Box sx={{ bgcolor: '#FFF', padding: 0, borderRadius: 0, boxShadow: 32 }}>
            <LoginField onBackClick={handleCloseLogin} />
          </Box>
        </Slide>
      </Modal>
    </Box>
  );
};

export default Landing;
