import React, { useEffect, useState, useRef } from 'react';

//mui elemek
import { AppBar, Toolbar, Box, IconButton, Slide } from '@mui/material';

//Iconok és képek
import AppsIcon from '@mui/icons-material/Apps';

//Componensek
import BaseLogo from '../../components/BaseLogo';
import PageHeaderLoginDetailsBody from '../../components/PageHeaderLoginDetailsBody';
import PageHeaderUserDetailsBody from '../../components/PageHeaderUserDetailsBody';

const NavHeader = ({ onMenuOpen }) => {
    const [showHeader, setShowHeader] = useState(true);
    const scrollTimeoutHeader = useRef(null);
    
    // Fejléc láthatóságának kezdeti beállításához
    useEffect(() => {
        setShowHeader(true); // Az oldal betöltésekor megjeleníti a fejlécet
    }, []);
    
    // Görgetési események kezelése a fejlécre
    useEffect(() => {
        const handleScrollForHeader = () => {
            setShowHeader(false); // Görgetéskor elrejti a fejlécet
            clearTimeout(scrollTimeoutHeader.current);
            scrollTimeoutHeader.current = setTimeout(() => {
                setShowHeader(true); // 1 másodperccel a görgetés megállása után megjeleníti a fejlécet
            }, 1000);
        };
    
        window.addEventListener('scroll', handleScrollForHeader);
        return () => {
            window.removeEventListener('scroll', handleScrollForHeader);
            clearTimeout(scrollTimeoutHeader.current);
        };
    }, []);

    return (
        <Slide direction="down" in={showHeader} timeout={500}>
            <AppBar position="fixed" sx={{ backgroundColor: '#FFF', boxShadow: 'none' }}>
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <IconButton
                            size="small"
                            onClick={onMenuOpen}
                            sx={{
                                backgroundColor: '#08979C',
                                display: { xs: 'none', sm: 'flex' },
                                '&:hover': {
                                    transform: 'scale(1.04)',
                                    boxShadow: 'none',
                                    backgroundColor: '#68C9CC'
                                }
                            }}
                        >
                            <AppsIcon sx={{ fontSize: 32, color: '#FFF', padding: 1 }} />
                        </IconButton>
                        <BaseLogo />
                    </Box>

                    <Box sx={{ display: 'flex', gap: 1 }}>
                        <PageHeaderLoginDetailsBody />
                        <PageHeaderUserDetailsBody />
                    </Box>
                </Toolbar>
            </AppBar>
        </Slide>
    );
};

export default NavHeader;