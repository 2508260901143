
import React, { useEffect, useState, useRef } from 'react';

//Mui elemek
import { IconButton, Slide } from '@mui/material'

//iconok és képek
import AppsIcon from '@mui/icons-material/Apps';

const FloatMenuButton = ({handleOpen}) => {
    const [showButton, setShowButton] = useState(true);
    const scrollTimeoutButton = useRef(null);

    useEffect(() => {
        setShowButton(true); // Az oldal betöltésekor megjeleníti a button-t
    }, []);

    // useEffect a lebegő gomb láthatóságának vezérlésére
    useEffect(() => {
        setShowButton(true); // Az oldal betöltésekor megjeleníti a gombot

        const handleScrollForButton = () => {
            setShowButton(false);
            clearTimeout(scrollTimeoutButton.current);
            scrollTimeoutButton.current = setTimeout(() => {
                setShowButton(true); // 1 másodperccel a görgetés megállása után megjeleníti a gombot
            }, 1000);
        };

        window.addEventListener('scroll', handleScrollForButton);
        return () => {
            window.removeEventListener('scroll', handleScrollForButton);
            clearTimeout(scrollTimeoutButton.current);
        };
    }, []); // A `[]` garantálja, hogy csak egyszer fusson le a `useEffect`

    

  return (
            <Slide direction='up' in={showButton} timeout={500}>
                <IconButton
                    size="small" 
                    onClick={handleOpen} 
                    sx={{ 
                        backgroundColor: '#08979C',
                        position: 'fixed', 
                        bottom: 16,
                        right: 16,
                        zIndex: { xs: 1000, sm: 'auto' },
                        display: {xs: 'flex', sm: 'none' },
                        '&:hover': {
                            transform: 'scale(1.04)',
                            boxShadow: 'none',
                            backgroundColor: '#68C9CC'
                        }
                    }}
                >
                    <AppsIcon sx={{ fontSize: 32, color: '#FFF', padding: 1 }} />
                </IconButton>
            </Slide>
  )
}

export default FloatMenuButton
