import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { StateProvider } from './context/DataContext';

import Dashboard from './layouts/Dashboard';
import NewOrganization from './pages/neworganization/NewOrganization';
import Landing from './pages/Landing';
import DayOpen from './pages/dayOpen/DayOpen';
import TobaccoHome from './pages/tobaccoHome/TobaccoHome';
import CashOut from './pages/operations/cashOut/CashOut';
import CashIn from './pages/operations/cashIn/CashIn';
import CloseFile from './pages/operations/closeFile/CloseFile';
import ChangeFile from './pages/operations/changeFile/ChangeFile';

function App() {
  return (
    <StateProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} /> {/* Alapértelmezett oldal: Landing Page */}
          <Route path='/tobacco' element={<Dashboard><TobaccoHome /></Dashboard>}></Route>
          <Route path="/dayopen" element={<Dashboard><DayOpen /></Dashboard>} /> {/*  Napnyitás */}
          <Route path="/cashout" element={<Dashboard><CashOut></CashOut></Dashboard>} /> {/*  Pénz ki  */}
          <Route path="/cashin" element={<Dashboard><CashIn></CashIn></Dashboard>} /> {/*  Pénz be */}
          <Route path="/changefile" element={<Dashboard><ChangeFile></ChangeFile></Dashboard>} /> {/*  MűszakVálátás */}
          <Route path="/closefile" element={<Dashboard><CloseFile /></Dashboard>} /> {/*  MűszakZárás */}
          <Route path="/new-organization" element={<NewOrganization />} /> {/*  Új szervezet létrehozása */}
        </Routes>
      </Router>
    </StateProvider>
  );
}

export default App;