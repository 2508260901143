import { useContext, useEffect, useState } from 'react';

import useDebounce from '../../../../hooks/useDebounce';
import { DataContext } from '../../../../context/DataContext';
import { getModules } from '../../../../services/Api';

import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid2,
  TextField,
  IconButton,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AppsIcon from '@mui/icons-material/Apps';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import Inventory2OutlinedIcon from '@mui/icons-material/Inventory2Outlined';
import SavingsOutlinedIcon from '@mui/icons-material/SavingsOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';

import OpGroupElement from './content/OpGroupElement';
import OpElement from './content/OpElement';

const OpList = () => {
  const {user, ops, icons, setMsg} = useContext(DataContext);

  const [modules, setModules] = useState([]);
  const [showModules, setShowModules] = useState([]);

  const [moduleIcons] = useState({
    // { icon: <FavoriteBorderIcon />, name: 'Kedvencek', opGroupID: 2, color: '#FF6F61' },
    1: <PointOfSaleIcon />,
    2: <DescriptionOutlinedIcon />,
    4: <Inventory2OutlinedIcon />,
    9: <SavingsOutlinedIcon />,
    3: <GroupOutlinedIcon />,
    8 : <SettingsOutlinedIcon />
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedOpGroupID, setSelectedOpGroupID] = useState(null);

  useEffect(() => {
    getModules(user.company.id).then(res => setModules(res));
  }, []);

  useDebounce(() => {
    try {
      const rx = new RegExp(`${searchTerm}`,'i');

      setShowModules(modules.filter(item => rx.test(item.name)));
    }
    catch(err) {
      setSearchTerm("");
      setMsg({
        color: "error",
        message: "Erre a karakterre nem lehet keresni"
      });
    }
  }, [modules, searchTerm], 500);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  if(selectedOpGroupID) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <Accordion
          defaultExpanded
          sx={{
            maxWidth: '100%',
            width: '100%',
            height: '100%',
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="operation-content"
            id="operation-header"
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
              {/* Vissza gomb */}
              <IconButton
                onClick={(event) => {
                  event.stopPropagation(); // Megakadályozza az Accordion kezelését
                  setSelectedOpGroupID(null); // Visszaáll az alapértelmezett nézetre
                }}
                sx={{
                  color: '#8c8c8c',
                  '&:hover': {
                    color: '#08979C',
                  },
                }}
              >
                <ArrowBackIcon sx={{ fontSize: '18px'}} />
              </IconButton>
              <Typography sx={{ fontWeight: 'bold', color: '#08979C' }}>
                {showModules.find(item => item.id === selectedOpGroupID)?.name}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid2 container spacing={0.5}>
              {ops.filter(op => user.store ? (op.visibility === 3 && op.group_id === selectedOpGroupID) || (op.visibility === 2 && op.group_id === selectedOpGroupID) : op.visibility === 3 && op.group_id === selectedOpGroupID).map(item => (
                <Grid2 size={{ xs: 6, sm: 3 }} key={item.id}>
                  <OpElement
                    icon={icons[item.operation]}
                    name={item.name}
                    color={item.color_code_1}
                    path={item.path}
                  />
                </Grid2>
              ))}
            </Grid2>
          </AccordionDetails>
        </Accordion>
      </Box>
    );
  }

  return (
    <Box sx={{ flexGrow: 1, height: 'auto' }}>
      <Accordion
        defaultExpanded
        sx={{
          maxWidth: '100%',
          width: '100%',
          height: '100%',
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="operation-content"
          id="operation-header"
        >
          <Typography sx={{ fontWeight: 'bold', color: '#08979C' }}>Műveletek</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <TextField
            size="small"
            label="Keresés"
            variant="outlined"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{
              marginBottom: 1,
              width: '100%',
              '& .MuiInputBase-root': {
                fontSize: '14px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root': {
                fontSize: '12px',
                color: '#8c8c8c',
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#08979C',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#d9d9d9',
                },
                '&:hover fieldset': {
                  borderColor: '#08979C',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#68C9CC',
                },
              },
            }}
          />
          <Grid2 container spacing={0.5}>
            {showModules.map(item => (
              <Grid2 size={{ xs: 6, sm: 3 }} key={item.id}>
                <OpGroupElement
                  icon={moduleIcons[item.id]}
                  name={item.name}
                  opGroupID={item.id}
                  color={item.color}
                  onClick={() => setSelectedOpGroupID(item.id)}
                />
              </Grid2>
            ))}
          </Grid2>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default OpList;