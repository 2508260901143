import { Typography, Box, Grid2, Button } from '@mui/material';
import React from 'react';
import DoneIcon from '@mui/icons-material/Done';

const PackageElement = ({ title, description, features, onSelect }) => {
  return (
    <Box onClick={onSelect} sx={{ display: 'flex', flexDirection: 'column', width: '100%', borderRadius: 2, cursor: 'pointer', '&:hover': { backgroundColor: '#F8F8F8', boxShadow: 1 } }}>
      {/* Csomag címe és leírása */}
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%', padding: 1, gap: 1 }}>
        <Typography sx={{ padding: 0.8, backgroundColor: '#08979C', color: '#fff', fontFamily: 'Roboto', fontSize: '14px', fontWeight: '500', borderRadius: 2 }}>
          {title}
        </Typography>
        <Typography sx={{ padding: 0.8, color: '#8c8c8c', fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300' }}>
          {description}
        </Typography>
      </Box>

      {/* Pipás elemek 3 oszlopban - Grid2 struktúra változatlan */}
      <Box sx={{ width: '100%', padding: 1 }}>
        <Grid2 container spacing={1}>
          {features.map((feature, index) => (
            <Grid2 item size={{ xs: 12, sm: 4 }} key={index}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                <DoneIcon sx={{ color: '#08979C', fontSize: '20px' }} />
                <Typography sx={{ fontFamily: 'Roboto', fontSize: '12px', fontWeight: '300' }}>
                  {feature}
                </Typography>
              </Box>
            </Grid2>
          ))}
        </Grid2>
      </Box>

      {/* Kiválaszt gomb */}
      <Box sx={{ display: 'flex', width: '100%', padding: 1, alignItems: 'center', justifyContent: 'flex-end', boxSizing: 'border-box' }}>
        <Button size="small" sx={{ backgroundColor: '#68C9CC', color: '#FFF', '&:hover': { backgroundColor: '#08979C' } }} onClick={onSelect}>
          Kiválaszt
        </Button>
      </Box>
    </Box>
  );
};

export default PackageElement;